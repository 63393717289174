import React, { useState } from 'react';
import { Currencies, CURRENCY, get_precision, Money } from '../../../utils/money';
import { FormControl, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Box, IconButton, Button } from '@mui/material';
import Decimal from 'decimal.js';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { parseFloatWithPrecision } from '../../../utils/decimal';

interface MoneyInputProps {
    money: Money[];
    setMoney: (money: Money[]) => void;
    label: string;
}

function MoneyInput({ money, setMoney, label }: MoneyInputProps) {
    const [availableCurrencies, setAvailableCurrencies] = useState<CURRENCY[]>(
        Currencies.filter(item => money.filter(m => m[0] == item).length === 0)
    )
    const handleAmountChange = (index: number, value: string) => {
        const newVal = parseFloatWithPrecision(value).toDecimalPlaces(get_precision(money[index][0]))
        const newMoney: Money[] = money.map((current, i) => (i === index ? [current[0], newVal] : current));
        setMoney(newMoney);
    };

    const handleCurrencyChange = (index: number, newCurrency: CURRENCY) => {
        const newVal = money[index][1].toDecimalPlaces(get_precision(newCurrency))
        const newMoney: Money[] = money.map((current, i) => (i === index ? [newCurrency, newVal] : current));
        setAvailableCurrencies([...availableCurrencies.filter(item => item != newCurrency), money[index][0]])
        setMoney(newMoney);
    };

    return (
        <Box
            component="fieldset"
            sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                height: "100%",

            }}
        >
            <legend style={{ padding: 3, color: "#888" }}>{label}</legend>

            {/* Render all money inputs */}
            {money.map((item, index) => (
                <FormControl fullWidth key={index} sx={{ marginBottom: 2 }}>
                    <TextField
                        onFocus={(e) =>
                            e.target.addEventListener(
                                'wheel',
                                (e) => e.preventDefault(),
                                { passive: false }
                            )
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FormControl variant="standard" sx={{ minWidth: 80 }}>
                                        <Select
                                            value={item[0]}
                                            onChange={(e) => handleCurrencyChange(index, e.target.value as CURRENCY)}
                                            autoWidth
                                            input={<OutlinedInput />}
                                            sx={{
                                                height: 40,
                                                fontSize: '0.875rem',
                                                borderRadius: 0,
                                                '& .MuiSelect-icon': {
                                                    right: 4,
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none',
                                                },
                                            }}
                                        >
                                            {
                                                [...availableCurrencies, item[0]].sort((a, b) => b.localeCompare(a)).map(curr => <MenuItem key={curr} value={curr}>{curr}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <DeleteIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'gray',
                                            '&:hover': {
                                                color: 'red',
                                            },
                                        }}
                                        onClick={() => {
                                            setAvailableCurrencies([...availableCurrencies, item[0]])
                                            setMoney(money.filter((_, i) => i !== index));
                                        }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        type="number"
                        value={item[1]}
                        inputProps={{
                            maxLength: 13,
                            step: '0.01',
                        }}
                        onChange={(e) => { handleAmountChange(index, e.target.value) }}
                        variant="outlined"
                    />
                </FormControl>
            ))}

            {/* "Add More" Button below the inputs */}
            {availableCurrencies.length > 0 && <Box sx={{ textAlign: 'center', display: "absolute", bottom: 0, }}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setAvailableCurrencies(availableCurrencies.slice(1))
                        setMoney([...money, [availableCurrencies[0], Decimal(0)]])
                    }}
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 500000,
                    }}
                >
                    <AddIcon />
                </Button>
            </Box>
            }

        </Box>
    );
}

export default MoneyInput;
