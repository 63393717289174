import { TextField } from '@mui/material';
import Decimal from 'decimal.js';
import React, { useState } from 'react';

interface WeightInputProps {
    weight: Decimal;
    setWeight: (w: Decimal) => void;
}

function WeightInput({ weight, setWeight }: WeightInputProps) {
    return (
        <TextField
            label="Total Weight (kg)"
            type="number"
            variant="outlined"
            required
            fullWidth
            value={weight}
            inputProps={{
                maxLength: 12,
                step: '0.01',
                min: 0,
            }}
            onFocus={(e) =>
                e.target.addEventListener(
                    'wheel',
                    (e) => e.preventDefault(),
                    { passive: false }
                )
            }
            onChange={(e) => {
                let newWeight = Decimal(0)
                if (e.target.value && e.target.value != '')
                    newWeight = Decimal(e.target.value).toDecimalPlaces(2)
                setWeight(newWeight)
            }
            }
        />
    );
}

export default WeightInput;