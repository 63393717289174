import Decimal from "decimal.js";

export enum CURRENCY {
    USD = 'USD',
    LBP = 'LBP',
    EUR = 'EUR',
}

export const Currencies = Object.values(CURRENCY)

export type Money = [CURRENCY, Decimal]

export function reduce(money: Money[]): Map<CURRENCY, Decimal> {
    const res = new Map<CURRENCY, Decimal>();
    money.forEach(m => res.set(m[0], (res.get(m[0]) ?? Decimal(0)).plus(m[1])))
    return res;
}

export function add(m1: Money[], m2: Money[]): Money[] {
    const r1 = reduce(m1)
    const r2 = reduce(m2)
    r2.forEach((amount, currency) => r1.set(currency, (r1.get(currency) ?? Decimal(0)).add(amount)))
    const res: Money[] = [];
    r1.forEach((val, key) => { if (!val.isZero()) res.push([key, val]) });
    return res;
}

export function minus(m1: Money[], m2: Money[]): Money[] {
    const r1 = reduce(m1)
    const r2 = reduce(m2)
    r2.forEach((amount, currency) => r1.set(currency, (r1.get(currency) ?? Decimal(0)).minus(amount)))
    const res: Money[] = [];
    r1.forEach((val, key) => { if (!val.isZero()) res.push([key, val]) });
    return res;
}

export function get_symbol(currency: CURRENCY): string {
    switch (currency) {
        case CURRENCY.USD: return "$"
        case CURRENCY.LBP: return "LL"
        case CURRENCY.EUR: return "€"
    }
}

export function get_precision(currency: CURRENCY): number {
    switch (currency) {
        case CURRENCY.USD: return 2
        case CURRENCY.LBP: return 0
        case CURRENCY.EUR: return 2
    }
}

export function displayMoney(money: Money[]) {
    let val = ""
    money.forEach((item, index) => { if (!Decimal(item[1]).isZero()) val += `${index > 0 ? ", " : ""}${item[1]} ${get_symbol(item[0])}` })
    return val.length == 0 ? "0" : val
}

export function isNonZero(money: Money[]): boolean {
    let hasMoney = false;
    money.forEach((item, index) => { if (!Decimal(item[1]).isZero()) hasMoney = true })
    return hasMoney
}