import React, { useState } from 'react';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { Button, FormControl, Grid, MenuItem, Select, TableCell, TableRow, Typography } from '@mui/material';
import { showDeliveryState, showPaymentState } from '../../common/styles/order-states.style';
import { isBrowser, isMobile } from 'react-device-detect';

export interface MultipleMatchesFormProps {
    orders: OrderType[];
    onOrderSelected: (order: OrderType) => void;
}

const orderRow = (order: OrderType) => {
    return <Grid container spacing={5} key={order.id} sx={{ marginBottom: 2, }}>
        {/* Order ID */}
        <Grid item xs={6} sm={3}>
            <Typography variant="body2" color="textSecondary">Order ID</Typography>
            <Typography variant="body1">{order.order_id}</Typography>
        </Grid>

        {/* Reference ID */}
        <Grid item xs={6} sm={3}>
            <Typography variant="body2" color="textSecondary">Reference ID</Typography>
            <Typography variant="body1">{order.reference_id}</Typography>
        </Grid>

        {/* Merchant Name */}
        <Grid item xs={6} sm={3}>
            <Typography variant="body2" color="textSecondary">Merchant</Typography>
            <Typography variant="body1">{order.merchant.name}</Typography>
        </Grid>

        {/* Delivery State */}
        <Grid item xs={6} sm={3}>
            <Typography variant="body2" color="textSecondary">Delivery State</Typography>
            <Typography variant="body1">{showDeliveryState(order.delivery_state)}</Typography>
        </Grid>
    </Grid>

}

export function MultipleMatchesForm({ orders, onOrderSelected }: MultipleMatchesFormProps) {
    const [selectedOrderIndex, setSelectedOrderIndex] = useState<number>(-1);
    return (
        <>
            <FormControl fullWidth>

                <Select
                    value={selectedOrderIndex}
                    fullWidth
                    required
                    style={{ padding: "0 !important" }}
                    disableUnderline={true}
                    variant='standard'
                    sx={{
                        '& .MuiSelect-select': {
                            paddingRight: 4,
                            paddingLeft: 2,
                            paddingTop: 1,
                            paddingBottom: 1,
                        }
                    }}
                    onChange={(e) => {
                        const index = parseInt(e.target.value.toString())
                        index >= 0 && setSelectedOrderIndex(index)
                    }}
                >
                    <MenuItem key={"-1"} value={"-1"} disabled>
                        <TableRow key={"-1"}>
                            <TableCell
                                scope="row"
                            >
                                Please select an order
                            </TableCell>
                        </TableRow>
                    </MenuItem>

                    {orders.map((order, index) => (
                        <MenuItem key={order.id} value={index} sx={{ borderBottom: "2px solid #eee" }} >
                            {orderRow(order)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <br /> <br />


            <FormControl fullWidth sx={{ textAlign: "center" }}>
                <Button
                    onClick={() => onOrderSelected(orders[selectedOrderIndex])}
                    type='submit'
                    variant='contained'
                    size='large'
                    disabled={selectedOrderIndex < 0}
                >
                    Add Order
                </Button>

            </FormControl>


        </>

    );
}

export default MultipleMatchesForm;