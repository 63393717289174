import axios from "axios";
import { config } from "process";
import { API_ENDPOINT } from "../../../configurations/global.config";
import { DriverType } from "../../../interfaces/delivery-company/drivers";
import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/user-context/user-context";
import { UserType } from "../../../enums/users";
import { User } from "../../../interfaces/common/authentication";
import { MerchantType } from "../../../interfaces/delivery-company/merchants";
import { CustomerType } from "../../../interfaces/common/customer";
import { DeliveryState, PaymentState } from "../../../enums/orders";
import { regions, RegionType } from "../../../data/regions";
import AutocompleteWithThrottle from "../autocomplete-with-throttle/autocomplete-with-throttle";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';


export interface IFilterOption {
    display(): string
    toLabel(): string
    toQuery(): { key: string; value: string }
    isFrozen(): boolean
}

export type ActorType = "driver" | "merchant" | "customer"

export interface Actor {
    actortype: ActorType;
    pk: string;
    name: string;
    phone_number: string;
}


// ActorFilterOption represents a filter option based on driver, customer or merchant
export class ActorFilterOption implements IFilterOption {
    actor: Actor;
    frozen: boolean;
    constructor(actor: Actor, frozen?: boolean) {
        this.actor = actor
        this.frozen = frozen ?? false
    }

    public display(): string {
        return `${this.actor.actortype}: ${this.actor.name} ${this.actor.phone_number}`
    }

    public toQuery(): { key: string; value: string; } {
        return {
            key: this.actor.actortype,
            value: this.actor.pk,
        }
    }

    public toLabel(): string {
        return `${this.actor.name} ${this.actor.phone_number}`
    }

    public isFrozen(): boolean {
        return this.frozen
    }
}

export class DeliveryStateFilterOption implements IFilterOption {
    state: DeliveryState

    constructor(state: DeliveryState) {
        this.state = state
    }

    public display(): string {
        return `delivery state: ${this.state}`
    }

    public toLabel(): string {
        return this.state
    }

    public toQuery(): { key: string; value: string; } {
        return {
            key: "delivery_state",
            value: this.state,
        }
    }

    public isFrozen(): boolean {
        return false
    }
}

class PaymentStateFilterOption implements IFilterOption {
    state: PaymentState

    constructor(state: PaymentState) {
        this.state = state
    }

    public display(): string {
        return `payment state: ${this.state}`
    }

    public toLabel(): string {
        return this.state
    }

    public toQuery(): { key: string; value: string; } {
        return {
            key: "payment_state",
            value: this.state,
        }
    }

    public isFrozen(): boolean {
        return false
    }
}


class StringFilterOption implements IFilterOption {
    value: string
    key: string

    constructor(key: string, value: string) {
        this.key = key
        this.value = value
    }

    public display(): string {
        return `${this.key}: ${this.value}`
    }

    public toLabel(): string {
        return this.value
    }

    public toQuery(): { key: string; value: string; } {
        return {
            key: this.key,
            value: this.value,
        }
    }

    public isFrozen(): boolean {
        return false
    }
}


// AsyncFetchingHandlerFunc is function that receives a user object and returns a function that fetches 
// related IFilterOption items
type AsyncFetchingHandlerFunc = (user?: User | null) => (query: string) => Promise<IFilterOption[]>

// AsyncFilteringAttributes groups the necessary attributes required to render and run a filter
export interface AsyncFilteringAttributes {
    handler: AsyncFetchingHandlerFunc
    label: string;
}

// getFilterOptions returns a list of filter options
type getValues = () => IFilterOption[]

// AsyncFilteringAttributes groups the necessary attributes required to render and run a filter
export interface FilteringAttributes {
    getValues: getValues;
}

const getHandleFetchDrivers = (user?: User | null) => async (query: string): Promise<IFilterOption[]> => {
    const endpoint = `${API_ENDPOINT}/api/v1/drivers/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;
    const config = {
        headers: { Authorization: `Bearer ${user?.authToken}` },
    };
    try {
        const response = await axios.get<{
            count: number;
            results: DriverType[];
        }>(endpoint, config);

        return response.data.results.map((val: DriverType) => new ActorFilterOption({
            actortype: "driver",
            pk: val.id,
            name: val.name,
            phone_number: val.phone_number,
        }))
    } catch (e) {
        console.log("failed to fetch drivers")
    }
    return [];
};

const getHandleFetchMerchants = (user?: User | null) => async (query: string): Promise<IFilterOption[]> => {
    const endpoint = `${API_ENDPOINT}/api/v1/merchants/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;
    const config = {
        headers: { Authorization: `Bearer ${user?.authToken}` },
    };
    try {
        const response = await axios.get<{
            count: number;
            results: MerchantType[];
        }>(endpoint, config);

        return response.data.results.map((val: MerchantType) => new ActorFilterOption({
            actortype: "merchant",
            pk: val.id,
            name: val.name,
            phone_number: val.phone_number,
        }))
    } catch (e) {
        console.log("failed to fetch merchants")
    }
    return [];
};

const getHandleFetchCustomers = (user?: User | null) => async (query: string): Promise<IFilterOption[]> => {
    const endpoint = `${API_ENDPOINT}/api/org/customers/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;
    const config = {
        headers: { Authorization: `Bearer ${user?.authToken}` },
    };
    try {
        const response = await axios.get<{
            count: number;
            results: CustomerType[];
        }>(endpoint, config);

        return response.data.results.map((val: CustomerType) => new ActorFilterOption({
            actortype: "customer",
            pk: val.id,
            name: val.name,
            phone_number: val.phone_number,
        }))
    } catch (e) {
        console.log("failed to fetch customers")
    }
    return [];
};



const getHandleFetchDeliveryStates = (): IFilterOption[] => {
    return Object.values(DeliveryState).map(item => new DeliveryStateFilterOption(item))
}

const getHandleFetchPaymentStates = (): IFilterOption[] => {
    return Object.values(PaymentState).map(item => new PaymentStateFilterOption(item))
}

const getHandleFetchGovernorates = (): IFilterOption[] => {
    return Array.from(new Set(regions.map(item => item.governorate))).map(item => new StringFilterOption("governorate", item))
}

const getHandleFetchDistricts = (): IFilterOption[] => {
    return Array.from(new Set(regions.map(item => item.district))).map(item => new StringFilterOption("district", item))
}

export type FilterKey = "driver" | "merchant" | "customer" | "delivery state" | "payment state" | "district" | "governorate"
export const AllFilterKeys: FilterKey[] = ["driver", "merchant", "customer", "delivery state", "payment state", "district", "governorate"]

interface RenderFilterInputProps {
    filter: FilteringAttributes | AsyncFilteringAttributes
    selectedFilterOptions: IFilterOption[]
    setSelectedFilterOptions: any;
}
const RenderAsyncFilterInput = ({ filter, selectedFilterOptions, setSelectedFilterOptions }: RenderFilterInputProps) => {
    const { user } = useContext(AuthContext)
    return <AutocompleteWithThrottle<IFilterOption>
        label={(filter as AsyncFilteringAttributes).label}
        required={false}
        fetch={(filter as AsyncFilteringAttributes).handler(user)}
        getOptionLabel={(option: IFilterOption) => option.toLabel()}
        value={null}
        onChange={(e, value) => {
            if (value)
                setSelectedFilterOptions([...selectedFilterOptions, value])
        }}
    />
}

const RenderButtonsFilterInput = ({ filter, selectedFilterOptions, setSelectedFilterOptions }: RenderFilterInputProps) => {
    const { user } = useContext(AuthContext)
    return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2, height: "100%" }}>
        {(filter as FilteringAttributes).getValues()
            .filter(item => selectedFilterOptions.filter(option => option.toLabel() === item.toLabel()).length === 0)
            .map(
                item => <Button
                    onClick={() => setSelectedFilterOptions([...selectedFilterOptions, item])}
                    key={item.toLabel()}
                    variant="outlined"


                >
                    {item.toLabel()} <AddIcon fontSize="small" />
                </Button>
            )
        }

    </Box>
}

const RenderAutocompleteFilterInput = ({ filter, selectedFilterOptions, setSelectedFilterOptions }: RenderFilterInputProps) => {
    const { user } = useContext(AuthContext)
    const [inputVal, setInputVal] = useState<string>("")
    return <Autocomplete
        disablePortal
        fullWidth
        isOptionEqualToValue={(option, value) =>
            option.display() === value.display()
        }
        onChange={(_, newValue: IFilterOption | null) => {
            if (newValue) {
                setInputVal("")
                setSelectedFilterOptions([...selectedFilterOptions, newValue])
            }

        }}
        inputValue={inputVal}
        onInputChange={(event, newInputValue) => {
            if (event === null) return;
            setInputVal(newInputValue);
        }}
        options={(filter as FilteringAttributes).getValues().filter(item => selectedFilterOptions.filter(option => option.toLabel() === item.toLabel()).length === 0)}
        getOptionLabel={(option) => option.toLabel()}
        renderInput={(params) => (
            <TextField {...params} label={"Start typing..."} />
        )}
    />
}

const AvailableFilters = new Map<FilterKey, (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) => JSX.Element>(
    [
        ["driver",
            (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) =>
                <RenderAsyncFilterInput
                    selectedFilterOptions={selectedFilterOptions}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    filter={{ handler: getHandleFetchDrivers, label: "Search by name or phone number" }} />
        ],
        ["customer",
            (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) =>
                <RenderAsyncFilterInput
                    selectedFilterOptions={selectedFilterOptions}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    filter={{ handler: getHandleFetchCustomers, label: "Search by name or phone number" }} />
        ],
        ["merchant",
            (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) =>
                <RenderAsyncFilterInput
                    selectedFilterOptions={selectedFilterOptions}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    filter={{ handler: getHandleFetchMerchants, label: "Search by name or phone number" }} />
        ],
        [
            "delivery state",
            (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) =>
                <RenderButtonsFilterInput
                    selectedFilterOptions={selectedFilterOptions}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    filter={{ getValues: getHandleFetchDeliveryStates }} />

        ],
        [
            "payment state",
            (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) =>
                <RenderButtonsFilterInput
                    selectedFilterOptions={selectedFilterOptions}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    filter={{ getValues: getHandleFetchPaymentStates }} />

        ],
        [
            "governorate",
            (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) =>
                <RenderButtonsFilterInput
                    selectedFilterOptions={selectedFilterOptions}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    filter={{ getValues: getHandleFetchGovernorates }} />

        ],
        [
            "district",
            (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) =>
                <RenderAutocompleteFilterInput
                    selectedFilterOptions={selectedFilterOptions}
                    setSelectedFilterOptions={setSelectedFilterOptions}
                    filter={{ getValues: getHandleFetchDistricts }} />

        ],
    ]
)


export const getFilters = (keys: string[]): Map<FilterKey, (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) => JSX.Element> => {
    const res = new Map<FilterKey, (selectedFilterOptions: IFilterOption[], setSelectedFilterOptions: any) => JSX.Element>()
    AvailableFilters.forEach((value, key) => {
        if (keys.includes(key))
            res.set(key, value)
    })
    return res
}





