import FeedIcon from '@mui/icons-material/Feed';
import HistoryIcon from '@mui/icons-material/History';
import InventoryIcon from '@mui/icons-material/Inventory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  LinearProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DeliveryState } from '../../../enums/orders';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import {
  cardHeaderStyle,
  cardStyle,
  cardTableStyle,
} from '../../common/styles/grid-card.style';
import {
  showDeliveryState,
  showPaymentState,
} from '../../common/styles/order-states.style';

import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import ConfirmAction from '../../common/confirm-action/confirm-action';
import CustomerLinkOrPopover from '../../common/customer-link-or-popover/customer-link-or-popover';
import { ErrorsFlattenner } from '../../common/errors-flattenner/errors-flattenner';
import { get_cod } from '../../../utils/cod';
import { OrgThemeContext } from '../../../contexts/theme-context';
import { displayMoney } from '../../../utils/money';
import { displayDimensions } from '../../common/dimensions-input/utils';

function MerchantOrder() {
  const { orderId } = useParams();
  const { orgId } = useParams();

  const [orderData, setOrderData] = React.useState<OrderType>();
  const [confirmDeleteOrder, setConfirmDeleteOrder] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<AxiosResponse>();
  const [message, setMessage] = React.useState<string>('');
  const { color } = React.useContext(OrgThemeContext);

  const { user } = React.useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  React.useEffect(() => {
    console.log('use effect');
    const getAndSetOrder = async () => {
      if (orderId !== undefined && orgId !== undefined) {
        setIsLoading(true);
        try {
          const endpoint = `${API_ENDPOINT}/api/v1/order/${orderId}/?org=${encodeURIComponent(orgId)}`;
          console.log(endpoint);
          const response = await axios.get<OrderType>(endpoint, config);
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setOrderData(response.data);
          }
        } catch (e) {
          setOrderData(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load order data: ' + e.message);
          } else {
            setMessage('Failed to load order data');
          }
          console.log(e);
        }
        setIsLoading(false);
      }
    };
    getAndSetOrder();
  }, [orderId]);

  const navigate = useNavigate();

  const deleteOrder = async () => {
    if (orderData && orderData.id !== '' && orgId !== undefined) {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/v1/order/${orderData.id}/?org=${encodeURIComponent(orgId)}`,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setOrderData(undefined);
          setMessage('Order deleted successfully');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to delete order: ' + e.response?.statusText);
        } else {
          setMessage('Failed to delete order');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Order Details
        </Typography>

        {isLoading && <LinearProgress />}

        {message !== '' && (
          <Alert
            severity={
              response && response.status >= 200 && response.status < 300
                ? 'success'
                : 'error'
            }
          >
            <>
              {message}

              {response && response.status >= 300 && (
                <ErrorsFlattenner rawErrors={response.data} />
              )}
            </>
          </Alert>
        )}

        {!isLoading && orderData && (
          <>
            <Grid container spacing={2}>
              {/* General Information */}
              <Grid xs={12} md={6}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <InventoryIcon />
                    <div>
                      <b>General Information</b>
                    </div>
                  </div>
                  <table style={cardTableStyle}>
                    <tr>
                      <th>Order Id</th>
                      <td>{orderData.order_id}</td>
                    </tr>
                    <tr>
                      <th>Reference Id</th>
                      <td>{orderData.reference_id}</td>
                    </tr>
                    <tr>
                      <th>Customer</th>
                      <td>
                        <CustomerLinkOrPopover customer={orderData.customer} />
                      </td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{orderData.address}</td>
                    </tr>
                    <tr>
                      <th>Region</th>
                      <td>
                        {orderData.governorate}/{orderData.district}
                      </td>
                    </tr>
                    <tr>
                      <th>Pickup Location</th>
                      <td>
                        {orderData.pickup_location}
                      </td>
                    </tr>
                  </table>
                </Card>
              </Grid>

              <Grid xs={12} md={6}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <FeedIcon />
                    <div>
                      <b>Extra Information</b>
                    </div>
                  </div>
                  <table style={cardTableStyle}>
                    <tr>
                      <th>Number of packages</th>
                      <td>{orderData.number_of_packages}</td>
                    </tr>

                    <tr>
                      <th>Total Weight</th>
                      <td>{orderData.weight.toString()} kg</td>
                    </tr>

                    <tr>
                      <th>Dimensions</th>
                      <td>{displayDimensions(orderData.dimensions)} cm³</td>
                    </tr>

                    <tr>
                      <th>Branch</th>
                      <td>{orderData.branch?.name}</td>
                    </tr>
                    <tr>
                      <th>Description</th>
                      <td>{orderData.description}</td>
                    </tr>
                  </table>
                </Card>
              </Grid>
              {/* Pricing Information */}
              <Grid xs={12} md={6}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <MonetizationOnIcon />
                    <div>
                      <b>Pricing & Status</b>
                    </div>
                  </div>
                  <table style={cardTableStyle}>
                    <tr>
                      <th>COD</th>
                      <td>
                        displayMoney(get_cod(orderData))
                      </td>
                    </tr>
                    <tr>
                      <th>Price due to merchant</th>
                      <td>
                        {displayMoney(orderData.price)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Delivery fee due{' '}
                        {orderData.delivery_fee_covered_by_merchant
                          ? 'merchant'
                          : 'customer'}
                      </th>
                      <td>{displayMoney(orderData.delivery_fee)}</td>
                    </tr>
                    <tr>
                      <th>Payment Status</th>
                      <td>{showPaymentState(orderData.payment_state)}</td>
                    </tr>
                    <tr>
                      <th>Delivery Status</th>
                      <td>{showDeliveryState(orderData.delivery_state)}</td>
                    </tr>

                    <tr>
                      <th>Labels</th>
                      <td>
                        {orderData.labels &&
                          orderData.labels.map((label) => (
                            <Chip
                              key={label.id}
                              label={`${label.key}: ${label.value}`}
                              sx={{ margin: 0.5 }}
                            />
                          ))}
                      </td>
                    </tr>
                  </table>
                </Card>
              </Grid>
              {/* State History */}
              <Grid xs={12} md={6}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <HistoryIcon />
                    <div>
                      <b>State History</b>
                    </div>
                  </div>
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Timestamp</b>
                          </TableCell>
                          <TableCell>
                            <b>Delivery State</b>
                          </TableCell>
                          <TableCell>
                            <b>Payment State</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {orderData.statestamps && (
                        <>
                          {orderData.statestamps.map((stateItem) => (
                            <TableRow
                              key={stateItem.timestamp}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {new Date(stateItem.timestamp).toLocaleString()}
                              </TableCell>
                              <TableCell>
                                {showDeliveryState(stateItem.delivery_state)}
                              </TableCell>
                              <TableCell>
                                {showPaymentState(stateItem.payment_state)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>

            <br />

            {orderData.delivery_state === DeliveryState.REQUESTED && (
              <div style={{ display: 'flex', gap: 7 }}>
                <Button
                  onClick={() =>
                    navigate(`/org/${orgId}/edit-order/${orderData.id}`)
                  }
                  variant="contained"
                >
                  Edit Order
                </Button>

                <Button
                  onClick={() => {
                    setConfirmDeleteOrder(true);
                  }}
                  variant="outlined"
                  color="error"
                >
                  Delete Order
                </Button>
              </div>
            )}
          </>
        )}
      </Box>

      <ConfirmAction
        message={'Are you sure you want to delete this order?'}
        action={deleteOrder}
        isOpen={confirmDeleteOrder}
        handleClose={() => setConfirmDeleteOrder(false)}
      >
        Attention: This action can not be undone
      </ConfirmAction>
    </div>
  );
}

export default MerchantOrder;
