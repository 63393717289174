/*
{
"delivery_state": "REQUESTED",
"payment_state": "UNINVOICED",
"organisation": 1
}

*/

import {
  Typography,
  Alert,
  FormControl,
  TextField,
  Button,
  LinearProgress,
  Switch,
} from '@mui/material';
import { useContext, useState } from 'react';

import { emptyDriver } from './driver-form.utils';
import {
  CreateDriverFormProps,
  DriverType,
} from '../../../../interfaces/delivery-company/drivers';
import MuiPhoneNumber from 'mui-phone-number';
import Grid from '@mui/material/Unstable_Grid2';
import ConfirmAction from '../../../common/confirm-action/confirm-action';
import axios, { AxiosResponse } from 'axios';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { UserType } from '../../../../enums/users';
import { ErrorsFlattenner } from '../../../common/errors-flattenner/errors-flattenner';
import { parseFloatWithPrecision } from '../../../../utils/decimal';
import MoneyInput from '../../../common/money-input/money-input';
import { MuiTelInput } from 'mui-tel-input';

function CreateOrEditDriver({
  defaultDriver,
  headless,
}: CreateDriverFormProps) {
  const [driverData, setDriverData] = useState<DriverType | undefined>(
    defaultDriver || emptyDriver
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [confirmDeleteDriver, setConfirmDeleteDriver] =
    useState<boolean>(false);
  const [confirmDeactivation, setConfirmDeactivation] =
    useState<boolean>(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const { user } = useContext(AuthContext);
  const [enableModifyPassword, setEnableModifyPassword] = useState(false);

  const canCreateDriver =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('add_driver');
  const canDeleteDriver =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('delete_driver');
  const canChangeDriver =
    user?.type === UserType.ORGANISATION ||
    user?.is_admin ||
    user?.permissions?.includes('change_driver');

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (driverData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Driver...');
      const requestData = {
        name: driverData.name,
        phone_number: driverData.phone_number,
        default_commission: driverData.default_commission,
        organisation: user?.organisation,
        password: driverData.password,
      };

      try {
        const endpoint = `${API_ENDPOINT}/api/v1/driver/${driverData.id}/`;

        let response;

        if (driverData.id === '') {
          console.log('request data:', requestData);
          // create new
          response = await axios.post(
            `${API_ENDPOINT}/api/v1/drivers/`,
            requestData,
            config
          );
        } else {
          // edit existing
          response = await axios.put(endpoint, requestData, config);
        }
        setResponse(response);
        setMessage('Driver saved successfully');
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to save driver: ' + e.response?.statusText);
        } else {
          setMessage('Failed to save driver');
        }
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleReactivate = async (e: any) => {
    e.preventDefault();
    if (driverData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Reactivating Driver...');
      const requestData = {
        name: driverData.name,
        phone_number: driverData.phone_number,
        default_commission: driverData.default_commission,
        organisation: user?.organisation,
        is_deleted: false,
      };

      try {
        const endpoint = `${API_ENDPOINT}/api/v1/driver/${driverData.id}/`;

        let response;

        if (driverData.id === '') {
          console.log('request data:', requestData);
          // create new
          response = await axios.post(
            `${API_ENDPOINT}/api/v1/drivers/`,
            requestData,
            config
          );
        } else {
          // edit existing
          response = await axios.put(endpoint, requestData, config);
        }
        setResponse(response);
        setMessage('Driver activated successfully');
        setDriverData({ ...driverData, is_deleted: false });
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to reactivate driver: ' + e.response?.statusText);
        } else {
          setMessage('Failed to reactivate driver');
        }
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const deleteDriver = async () => {
    if (driverData && driverData.id !== '') {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/v1/driver/${driverData.id}/`,
          config
        );
        setResponse(response);
        setDriverData(undefined);
        setMessage('Driver deleted successfully');
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to delete driver: ' + e.response?.statusText);
        } else {
          setMessage('Failed to delete driver');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  const deactivateDriver = async () => {
    if (driverData && driverData.id !== '') {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/v1/driver/${driverData.id}/`,
          config
        );
        setResponse(response);
        setDriverData({ ...driverData, is_deleted: true });
        setMessage('Driver deactivate successfully');
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to deactivate driver: ' + e.response?.statusText);
        } else {
          setMessage('Failed to deactivate driver');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      {!headless && (
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          {defaultDriver === undefined || defaultDriver.id === ''
            ? 'Create Driver'
            : 'Edit Driver'}
        </Typography>
      )}

      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message != '' && (
            <>
              <Alert
                severity={
                  response && response.status >= 200 && response.status < 300
                    ? 'success'
                    : 'error'
                }
              >
                <>
                  {message}

                  {response && response.status >= 300 && (
                    <ErrorsFlattenner rawErrors={response.data} />
                  )}
                </>
              </Alert>
              <br />{' '}
            </>
          )}

          {driverData && (
            <>
              <br></br>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Driver Name"
                    type="text"
                    variant="outlined"
                    required
                    value={driverData.name}
                    disabled={driverData.is_deleted}
                    onChange={(e) =>
                      setDriverData({ ...driverData, name: e.target.value })
                    }
                  />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <MuiTelInput
                    forceCallingCode
                    label="Phone Number"
                    defaultCountry={'LB'}
                    variant="outlined"
                    required
                    value={driverData.phone_number}
                    disabled={driverData.is_deleted}
                    onChange={(newPhone) => {
                      if (typeof newPhone === 'string') {
                        setDriverData({
                          ...driverData,
                          phone_number: newPhone,
                        });
                      }
                    }}
                  />
                </FormControl>
                <br />
                <br />

                <MoneyInput money={driverData.default_commission ?? []} setMoney={(money) => setDriverData({ ...driverData, default_commission: money })} label={'Default Commission'} />
                <br />
                <div
                  style={{
                    display: 'flex',
                    gap: 3,
                    alignItems: 'center',
                  }}
                >
                  {
                    <>
                      <Switch
                        onChange={() =>
                          setEnableModifyPassword(!enableModifyPassword)
                        }
                        checked={enableModifyPassword}
                      />
                      {!enableModifyPassword && <>Set password</>}
                    </>
                  }

                  {enableModifyPassword && (
                    <FormControl fullWidth>
                      <TextField
                        label="Driver Password"
                        type="password"
                        variant="outlined"
                        required
                        value={driverData.password}
                        onChange={(e) =>
                          setDriverData({
                            ...driverData,
                            password: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  )}
                </div>
                <br /> <br />
                <div style={{ display: 'flex', gap: 7 }}>
                  {!driverData.is_deleted && (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={
                        (!canCreateDriver && defaultDriver === undefined) ||
                        (!canChangeDriver && defaultDriver !== undefined)
                      }
                    >
                      Save Driver
                    </Button>
                  )}

                  {driverData.id !== '' && (
                    <>
                      <Button
                        onClick={() => {
                          driverData.is_deleted
                            ? setConfirmDeleteDriver(true)
                            : setConfirmDeactivation(true);
                        }}
                        variant="outlined"
                        color="error"
                        disabled={!canDeleteDriver}
                      >
                        {driverData.is_deleted
                          ? 'Permanently delete'
                          : 'Deactivate'}{' '}
                        driver
                      </Button>

                      {driverData.is_deleted && (
                        <Button
                          onClick={(e) => handleReactivate(e)}
                          variant="contained"
                          color="success"
                          disabled={!canChangeDriver}
                        >
                          Activate
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </form>
            </>
          )}
        </>
      )}

      <ConfirmAction
        message={'Are you sure you want to delete this driver?'}
        action={deleteDriver}
        isOpen={confirmDeleteDriver}
        handleClose={() => setConfirmDeleteDriver(false)}
        isSevereAction={true}
        confirmSevereActionTarget="delete driver"
      >
        Attention: This action will delete all orders registered for this driver
      </ConfirmAction>

      <ConfirmAction
        message={'Are you sure you want to deactivate this driver?'}
        action={deactivateDriver}
        isOpen={confirmDeactivation}
        handleClose={() => setConfirmDeactivation(false)}
        isSevereAction={false}
        confirmSevereActionTarget="deactivate driver"
      >
        <Alert severity="info">
          This action will not delete associated orders. You can always
          reactivate the driver account in the future.
        </Alert>
      </ConfirmAction>
    </>
  );
}

export default CreateOrEditDriver;
