export enum DeliveryState {
  REQUESTED = 'Requested',
  APPROVED = 'Approved',
  IN_TRANSIT = 'In Transit',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
}

export enum PaymentState {
  UNINVOICED = 'Uninvoiced',
  INVOICED_FOR_DRIVER = 'Invoiced - Driver',
  INVOICED_FOR_MERCHANT = 'Invoiced - Merchant',
}
