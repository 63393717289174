import React, { useContext } from 'react';
import { PageFooter } from '../../../common/pdf-builders-utils/page-footer';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { Br, titleStyles } from '../../../common/pdf-builders-utils/styles';
import { Aggregation, OrdersSummary, ReportSummary } from './profit-report';
import { OrgThemeContext } from '../../../../contexts/theme-context';
import { add, displayMoney, minus, Money } from '../../../../utils/money';

const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    borderBottom: '1px solid #E0E0E0',
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 1000,
    marginBottom: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  column: {
    flex: 1,
  },
  text: {
    fontSize: 12,
  },
});

const sumMoney = (
  ordersSummaries: OrdersSummary[],
  extractor: (agg: Aggregation) => Money[]
): Money[] => {
  let result: Money[] = [];
  ordersSummaries.forEach((summary) => {
    result = add(result, extractor(summary.uninvoiced))
    result = add(result, add(extractor(summary.invoiced_for_driver), extractor(summary.invoiced_for_merchant)))
  });
  return result;
};

interface GlobalSummaryProps {
  reportSummary: ReportSummary;
  color: string;
}

function GlobalSummary({ reportSummary, color }: GlobalSummaryProps) {
  const deliveredOrdersPrice = sumMoney([reportSummary.delivered_orders], (item) => item.price)
  const deliveredOrdersDelivery = sumMoney([reportSummary.delivered_orders], (item) => item.delivery_fee)
  const deliveredOrdersCommission = sumMoney([reportSummary.delivered_orders], (item) => item.driver_commission)
  const deliveredOrdersProfit = minus(deliveredOrdersDelivery, deliveredOrdersCommission);

  const cancelledOrdersPrice = sumMoney([reportSummary.cancelled_orders], (item) => item.price)
  const cancelledOrdersDelivery = sumMoney([reportSummary.cancelled_orders], (item) => item.delivery_fee)
  const cancelledOrdersCommission = sumMoney([reportSummary.cancelled_orders], (item) => item.driver_commission)
  const cancelledOrdersProfit = minus(cancelledOrdersDelivery, cancelledOrdersCommission);


  return (
    <View wrap={false}>
      <PageFooter color={color} />

      <View style={{ ...titleStyles.titleContainer, marginTop: 10 }}>
        <Text style={titleStyles.sectionTitle}>Summary</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Delivered Orders</Text>
        <View style={styles.row}>
          <Text style={styles.column}>Number of delivered orders:</Text>
          <Text style={styles.column}>
            {reportSummary.delivered_orders.count} orders
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total price:</Text>
          <Text style={styles.column}>{displayMoney(deliveredOrdersPrice)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total delivery fee:</Text>
          <Text style={styles.column}>{displayMoney(deliveredOrdersDelivery)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total driver commission:</Text>
          <Text style={styles.column}>{displayMoney(deliveredOrdersCommission)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Net Profit:</Text>
          <Text style={styles.column}>{displayMoney(deliveredOrdersProfit)}</Text>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Cancelled Orders</Text>
        <View style={styles.row}>
          <Text style={styles.column}>Number of cancelled orders:</Text>
          <Text style={styles.column}>
            {reportSummary.cancelled_orders.count} orders
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total price:</Text>
          <Text style={styles.column}>{displayMoney(cancelledOrdersPrice)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total delivery fee:</Text>
          <Text style={styles.column}>{displayMoney(cancelledOrdersDelivery)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Total driver commission:</Text>
          <Text style={styles.column}>{displayMoney(cancelledOrdersCommission)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.column}>Net Profit:</Text>
          <Text style={styles.column}>{displayMoney(cancelledOrdersProfit)}</Text>
        </View>
      </View>
    </View>
  );
}

export default GlobalSummary;
