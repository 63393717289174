import Decimal from "decimal.js";
import { OrderType } from "../interfaces/delivery-company/orders";
import { parseFloatWithPrecision } from "./decimal";
import { CURRENCY, Money, reduce } from "./money";

export const get_cod = (order: OrderType): Money[] => {
    const parsed_price: Money[] = order.price.map(item => [item[0], parseFloatWithPrecision(item[1].toString())])
    const parsed_delivery_fee: Money[] = order.delivery_fee.map(item => [item[0], parseFloatWithPrecision(item[1].toString())])

    const codMap = reduce(parsed_price)
    if (!order.delivery_fee_covered_by_merchant) {
        parsed_delivery_fee.forEach(item => {
            const currency = item[0]
            const amount = item[1]
            codMap.set(currency, (codMap.get(currency) ?? Decimal(0)).add(amount))
        })
    }

    const cod: Money[] = []
    codMap.forEach((value, key) => { if (!value.isZero()) cod.push([key, value]) })

    return cod;
}

export const get_price = (cod: Money[], deliveryFee: Money[], coveredByMerchant: boolean): Money[] => {
    const priceMap = reduce(cod);
    if (!coveredByMerchant) {
        deliveryFee.forEach(item => {
            const currency = item[0]
            const amount = item[1]
            priceMap.set(currency, (priceMap.get(currency) ?? Decimal(0)).minus(amount))
        })
    }

    const price: Money[] = []
    priceMap.forEach((value, key) => price.push([key, value]))
    return price
}