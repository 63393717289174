import Decimal from 'decimal.js';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { parseFloatWithPrecision } from '../../../utils/decimal';
import { IFieldConfig, IFooterRow } from './report';
import { add, displayMoney, minus } from '../../../utils/money';
import { compute_total_delivery_fee, compute_total_driver_commission, compute_total_price } from './utils';
import { get_cod } from '../../../utils/cod';

export const defaultOrgReportFieldsConfig: IFieldConfig[] = [
  {
    display_name: ['Order Id', '(Ref #)'],
    width_percentage: '20%',
    get_values: (order: OrderType) => {
      const vals = [`${order.order_id}`];
      order.reference_id && vals.push(`(${order.reference_id})`);
      return vals;
    },
    get_aggregate: (orders: OrderType[]) => [
      'Total',
      `${orders.length} orders`,
    ],
  },
  {
    display_name: ['Customer'],
    width_percentage: '23%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
    ],
  },
  {
    display_name: ['Address'],
    width_percentage: '30%',
    get_values: (order: OrderType) => [order.address || ''],
  },
  {
    display_name: ['Price'],
    width_percentage: '15%',
    get_values: (order: OrderType) => displayMoney(order.price).split(","),
    get_aggregate: (orders: OrderType[]) => displayMoney(compute_total_price(orders)).split(","),
  },
  {
    display_name: ['Del. Fee'],
    width_percentage: '15%',
    get_values: (order: OrderType) => displayMoney(order.delivery_fee).split(","),
    get_aggregate: (orders: OrderType[]) => displayMoney(compute_total_delivery_fee(orders)).split(","),
  },
  {
    display_name: ['Commission'],
    width_percentage: '15%',
    get_values: (order: OrderType) => displayMoney(order.driver_commission).split(","),
    get_aggregate: (orders: OrderType[]) => displayMoney(compute_total_driver_commission(orders)).split(","),
  },
];

export const defaultDriverReportFieldsConfig: IFieldConfig[] = [
  {
    display_name: ['Order Id', '(Ref #)'],
    width_percentage: '19%',
    get_values: (order: OrderType) => {
      const vals = [order.order_id];
      order.reference_id && vals.push(`(${order.reference_id})`);
      return vals;
    },
    get_aggregate: (orders: OrderType[]) => [
      'Total',
      `${orders.length} orders`,
    ],
  },
  {
    display_name: ['Customer'],
    width_percentage: '19%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
      order.district,
    ],
  },
  {
    display_name: ['Merchant'],
    width_percentage: '16%',
    get_values: (order: OrderType) => [order.merchant?.name ?? ''],
  },
  {
    display_name: ['Commission'],
    width_percentage: '15%',
    get_values: (order: OrderType) => displayMoney(order.driver_commission).split(","),
    get_aggregate: (orders: OrderType[]) => displayMoney(compute_total_driver_commission(orders)).split(","),
  },
  {
    display_name: ['COD'],
    width_percentage: '15%',
    get_values: (order: OrderType) => {
      return displayMoney(get_cod(order)).split(",");
    },
    get_aggregate: (orders: OrderType[]) => {
      const total_price = compute_total_price(orders);
      const total_delivery = compute_total_delivery_fee(orders.filter((item) => !item.delivery_fee_covered_by_merchant))
      const sum = add(total_price, total_delivery)
      return displayMoney(sum).split(",")
    },
  },
  {
    display_name: ['Notes'],
    width_percentage: '16%',
    get_values: (order: OrderType) => [order.public_note],
  },
];

export const defaultMerchantReportFieldsConfig: IFieldConfig[] = [
  {
    display_name: ['Order Id', 'Ref #'],
    width_percentage: '14%',
    get_values: (order: OrderType) => {
      const vals = [`${order.order_id}`];
      order.reference_id && vals.push(`(${order.reference_id})`);
      return vals;
    },
    get_aggregate: (orders: OrderType[]) => [
      'Total',
      `${orders.length} orders`,
    ],
  },
  {
    display_name: ['Customer'],
    width_percentage: '15%',
    get_values: (order: OrderType) => [
      order.customer?.name || 'unknown',
      order.customer?.phone_number || 'unknown',
    ],
  },
  {
    display_name: ['Address'],
    width_percentage: '27%',
    get_values: (order: OrderType) => [
      order.address,
    ],
  },
  {
    display_name: ['Delivery Fee'],
    width_percentage: '13%',
    get_values: (order: OrderType) => {
      return displayMoney(order.delivery_fee).split(",")
    },
    get_aggregate: (orders: OrderType[]) => displayMoney(compute_total_delivery_fee(orders)).split(","),
  },
  {
    display_name: ['Price'],
    width_percentage: '13%',
    get_values: (order: OrderType) => displayMoney(order.price).split(","),
    get_aggregate: (orders: OrderType[]) => displayMoney(compute_total_price(orders)).split(",")
  },
  {
    display_name: ['Notes'],
    width_percentage: '18%',
    get_values: (order: OrderType) => [order.public_note],
  },
];


export const defaultMerchantFooter: IFooterRow[] = [
  {
    label: 'Net due to merchant',
    compute_value: (orders) => {
      const orders_with_delivery_on_merchant = orders.filter(
        (order) => order.delivery_fee_covered_by_merchant
      );
      return displayMoney(minus(compute_total_price(orders), compute_total_delivery_fee(orders_with_delivery_on_merchant)))
    },
  },
]

export const defaultDriverFooter: IFooterRow[] = [
  {
    label: 'Net Due to Company',
    compute_value: (orders) => {
      const orders_excluding_already_paid_delivery = orders.filter(
        (order) => !order.delivery_fee_covered_by_merchant
      );

      let total_due_company = add(compute_total_price(orders), compute_total_delivery_fee(orders_excluding_already_paid_delivery))
      total_due_company = minus(total_due_company, compute_total_driver_commission(orders))

      return displayMoney(total_due_company)
    },
  },
]