import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { Box, Typography, Button, CircularProgress, Alert } from '@mui/material';
import axios, { AxiosError, isAxiosError } from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import AddressInputWithAutocomplete from '../../../common/adress-input-with-autocomplete/address-input-with-autocomplete';
import imageCompression from 'browser-image-compression';  // Import the image compression library

interface User {
    organisation?: {
        org_id: string;
    };
    storage_key?: string;
    authToken?: string;
    address?: string;
}

interface PickupLocationImageProps {
    user?: User;
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

function PickupLocationImage() {
    const { user, setUser } = useContext(AuthContext);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string>(`https://parcel-tracer-public.s3.eu-central-1.amazonaws.com/${user?.organisation?.org_id}/merchant/${user?.storage_key}/shop-image.png?v=${new Date().getTime()}`);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [pickupLocation, setPickupLocation] = useState<string>(user?.address ?? "");

    const config = {
        headers: { Authorization: `Bearer ${user?.authToken}` },
    };

    // Handle file selection with image compression
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null);
        setSuccessMessage(null);

        const selectedFile = event.target.files?.[0];

        if (!selectedFile) return;

        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

        // Check file type (client-side validation)
        if (!['png', 'jpeg', 'jpg'].includes(fileExtension || '')) {
            setError('Only PNG and JPEG/JPG images are allowed.');
            setSelectedImage(null);
            return;
        }

        // Compress the image before previewing
        try {
            const options = {
                maxSizeMB: 1, // Set max size in MB (you can adjust this value)
                maxWidthOrHeight: 800, // Set max width or height (you can adjust this value)
                useWebWorker: true, // Use web workers for better performance
            };

            const compressedFile = await imageCompression(selectedFile, options);  // Compress the image

            // Create a new File object from the compressed Blob and manually set the file name and extension
            const compressedFileWithName = new File([compressedFile], selectedFile.name, {
                type: compressedFile.type,
            });

            // Create a preview of the compressed image
            const compressedUrl = URL.createObjectURL(compressedFileWithName);
            setPreviewUrl(compressedUrl); // Update the preview with the compressed image

            // Set the selected image to the compressed file
            setSelectedImage(compressedFileWithName);

        } catch (compressionError) {
            setError('Image compression failed. Please try again.');
            console.error(compressionError);
        }
    };

    // Handle form submission
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setUploading(true);
        setError(null);
        setSuccessMessage(null);  // Reset the success message before uploading

        const formData = new FormData();
        if (selectedImage) {
            formData.append('image', selectedImage);
        }

        formData.append('pickup_location', pickupLocation);

        try {
            // Replace with your actual API endpoint
            const response = await axios.post(`${API_ENDPOINT}/api/merchant/profile`, formData, config);

            // On success, show the success message
            setUploading(false);
            setSuccessMessage('Pickup location information updated successfully!');
            if (user) setUser({ ...user, address: pickupLocation });
            setSelectedImage(null); // Clear selected file after successful upload
        } catch (err) {
            setUploading(false);
            setError('Failed to update pickup location information. Please try again.');
        }
    };

    return (
        <Box sx={{ margin: 'auto', textAlign: 'center' }}>

            {/* Error and Success Messages */}
            {error && <Typography color="error" sx={{ marginBottom: 2 }}>{error}</Typography>}
            {successMessage && (
                <Alert severity="success" sx={{ marginBottom: 2 }}>
                    {successMessage}
                </Alert>
            )}

            <Alert severity='info'>Share your pickup location to help our drivers find you more quickly.</Alert>
            <br />

            <AddressInputWithAutocomplete callback={function (address: string, district: string, governorate: string) { setPickupLocation(address) }} initialAddress={pickupLocation} onBlur={function (input: string): void { setPickupLocation(input) }} label={'Your pickup location'}></AddressInputWithAutocomplete>
            <br />
            <br />

            {/* Preview Section */}
            <Box sx={{ marginBottom: 2 }}>

                <Box
                    component="img"
                    sx={{
                        display: "flex",
                        justifyContent: "center",   // Center image horizontally
                        alignItems: "center",       // Center image vertically
                        height: "300px",            // Set a fixed height, this can be adjusted
                        maxWidth: "100%",           // Ensure it doesn't overflow its container
                        overflow: "hidden",         // Hide overflow for any image that exceeds the boundaries
                        borderRadius: "8px",        // Rounded corners
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Soft box shadow for a modern look
                        mx: "auto"
                    }}
                    src={previewUrl}
                    alt={"Photo unavailable"}
                />
                <Typography variant="body2" sx={{ color: '#777', marginTop: 1 }}>
                    {previewUrl ? 'Upload a photo of your shop' : 'No image selected'}
                </Typography>
            </Box>

            {/* File Upload Button */}
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="image-file-input"
                capture={true}  // This will open the camera on devices that support it
            />
            <label htmlFor="image-file-input">
                <Button variant="outlined" component="span" sx={{ width: '100%', marginBottom: 2 }}>
                    {'Change photo'}
                </Button>
            </label>

            {/* Upload Button */}
            <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                disabled={uploading}
                sx={{
                    paddingY: 1.5,
                }}
            >
                {uploading ? <CircularProgress size={24} sx={{ color: 'white', marginRight: 2 }} /> : 'Save'}
            </Button>
        </Box>
    );
}

export default PickupLocationImage;
