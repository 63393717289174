import React, { useContext, useEffect, useState } from 'react';
import { DriverType } from '../../../interfaces/delivery-company/drivers';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { DoubleDate } from '../../common/double-date-picker/double-date-picker.interfaces';
import { useImmer } from 'use-immer';
import { AuthContext } from '../../../contexts/user-context/user-context';
import axios from 'axios';
import {
  Box,
  Card,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  TablePagination,
} from '@mui/material';
import BulkAction from '../../common/bulk-action/bulk-action';
import DoubleDatePicker from '../../common/double-date-picker/double-date-picker';
import FilterBar from '../../common/filter-bar/filter-bar';
import OrdersListingTable from '../../common/orders-listing-table/orders-listing-table';
import SearchBar from '../../common/search-bar/search-bar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { defaultDriverFooter, defaultDriverReportFieldsConfig } from '../../common/report-builder/fields-config';
import { buildQueryParams } from '../../common/filter-bar/filter-bar.utils';
import { PaginationContext } from '../../../contexts/pagination-context';
import { parseFloatWithPrecision } from '../../../utils/decimal';
import { OrgThemeContext } from '../../../contexts/theme-context';
import { SortingByColumnState } from '../../common/orders-listing-table/utils';
import { ActorFilterOption, AllFilterKeys, DeliveryStateFilterOption, IFilterOption } from '../../common/filter-bar/types';
import { DeliveryState } from '../../../enums/orders';

export interface DriverOrdersProps {
  driver: DriverType;
}

function DriverOrders({ driver }: DriverOrdersProps) {
  const [ordersList, setOrdersList] = useState<OrderType[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [dateFilter, setDateFilter] = useState<DoubleDate>({
    startDate: null,
    endDate: null,
  });
  const { color } = useContext(OrgThemeContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedOrders, updateSelectedOrders] = useImmer<
    Map<string, OrderType>
  >(new Map());

  const resetSelectionState = () => {
    updateSelectedOrders((selectedOrders) => {
      selectedOrders.clear();
    });
  };

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const { paginationConfig, setPaginationConfig } =
    useContext(PaginationContext);

  const totalSelected = selectedOrders.size;

  const searchOptions = [
    'Order_Id',
    'Ref_Number',
    'Customer_Name',
    'Customer_Phone',
  ];

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPage,
    searchColumn: 'Order_Id',
    searchValue: '',
    searchOperation: 'equals',
  });

  const [filters, setFilters] = useState<IFilterOption[]>([
    new ActorFilterOption({
      actortype: "driver",
      pk: driver.id,
      name: driver.name,
      phone_number: driver.phone_number,
    }, true),
    new DeliveryStateFilterOption(DeliveryState.IN_TRANSIT),
  ]);


  const [sorting, setSorting] = useState<SortingByColumnState>({
    column: 'creation_date',
    order: 'decreasing',
  });

  useEffect(() => {
    let response: any;
    const getData = async () => {
      const relevant_fields = [
        'id',
        'order_id',
        'reference_id',
        'creation_date',
        'merchant',
        'customer',
        'driver',
        'address',
        'district',
        'governorate',
        'price',
        'delivery_fee',
        'driver_commission',
        'number_of_packages',
        'delivery_state',
        'payment_state',
        'branch',
        'tracking_hash',
        'public_note',
        'delivery_fee_covered_by_merchant',
      ];

      let endpoint = `${API_ENDPOINT}/api/v1/orders/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}&fields=${relevant_fields.join(',')}`;

      if (controller.searchColumn !== '' && controller.searchValue !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(controller.searchValue)}`;
      }

      const filterQueryParams = buildQueryParams(filters);
      endpoint += `${filterQueryParams}`;

      const { startDate, endDate } = dateFilter;

      if (startDate !== null) {
        endpoint += `&date${dateFilter.operation === 'assigned' ? '_assignment' : ''}_from=${encodeURIComponent(startDate.format('YYYY-MM-DD'))}`;
      }

      if (endDate !== null) {
        endpoint += `&date${dateFilter.operation === 'assigned' ? '_assignment' : ''}_to=${encodeURIComponent(endDate.format('YYYY-MM-DD'))}`;
      }

      endpoint += `&sort_by_${sorting.order}=${encodeURIComponent(sorting.column)}`;

      console.log(endpoint);
      try {
        response = await axios.get<{ count: number; results: OrderType[] }>(
          endpoint,
          config
        );

        if (response.statusText === 'OK') {
          setOrdersList(response.data.results);
          setOrdersCount(response.data.count);

          console.log(response.data);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
    console.log(response);
  }, [controller, dateFilter, filters, sorting]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationConfig({ ...paginationConfig, rowsPerPage: newRowsPerPage });
    setController({
      ...controller,
      rowsPerPage: newRowsPerPage,
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      page: 0,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  const resetPaginationControls = () => {
    setController({
      ...controller,
      page: 0,
    });
  };
  const filterQueryAction = (filters: IFilterOption[]) => {
    resetPaginationControls();
    setFilters(filters);
    resetSelectionState();
  };

  const dateFilterAction = (dateFilterValue: DoubleDate) => {
    resetPaginationControls();
    setDateFilter(dateFilterValue);
  };

  const resetAll = () => {
    resetPaginationControls();
    resetSelectionState();
  };

  return (
    <Box>
      <Card sx={{ padding: 2 }}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography
              variant="h5"
              component="h5"
              sx={{ textAlign: 'center', mt: 1, mb: 1 }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                }}
              >
                <ManageSearchIcon fontSize="large" color="primary" />
                <span>
                  <b>Search and Filter Controls</b>
                </span>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SearchBar
              searchOptions={searchOptions}
              searchQueryAction={searchQueryAction}
            />
            <br />

            <DoubleDatePicker dateFilterAction={dateFilterAction} />

            <br />

            <FilterBar
              filterQueryAction={filterQueryAction}
              currentlySelectedFilters={filters}
              filterKeys={AllFilterKeys.filter(item => item != "driver")}
            />
          </AccordionDetails>
        </Accordion>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 10,
            marginLeft: 10,
          }}
        >
          <Chip
            icon={<ChecklistIcon />}
            color="primary"
            label={`Selected ${totalSelected}/${ordersCount}`}
            variant={
              totalSelected === ordersCount && totalSelected > 0
                ? 'filled'
                : 'outlined'
            }
          />

          <BulkAction
            selectedOrders={selectedOrders}
            bulkEndpoint={`${API_ENDPOINT}/api/org/orders/bulk-update/`}
            refetchOrders={resetAll}
            extractAsPdf={{
              fileName: `${driver.name}.${new Date().toLocaleString()}.pdf`,
              fields_config: defaultDriverReportFieldsConfig,
              subtitle: `Driver: ${driver.name} / ${driver.phone_number}`,
              date: new Date().toLocaleString(),
              tableTitle: 'Driver Orders',
              table_footer_rows: defaultDriverFooter,
            }}
          />
        </div>

        <OrdersListingTable
          actionable={true}
          orders={ordersList}
          selectedOrders={selectedOrders}
          updateSelectedOrders={updateSelectedOrders}
          sorting={sorting}
          setSorting={setSorting}
          isLoading={isLoading}
        />

        <TablePagination
          component="div"
          onPageChange={handlePageChange}
          page={controller.page}
          count={ordersCount}
          rowsPerPage={controller.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
          labelRowsPerPage=""
          style={{ overflow: 'visible' }}
        />
      </Card>
    </Box>
  );
}

export default DriverOrders;
