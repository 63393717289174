import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography } from '@mui/material';

import DeliveryStatesWidget from './delivery-states-widget/delivery-states-widget';
import PaymentStatesWidget from './payment-states-widget/payment-states-widget';
import DeliveredOrdersHistoryWidget from './delivered-orders-history-widget/delivered-orders-history-widget';
import GovernorateDistributionWidget from './governorate-distribution-widget/governorate-distribution-widget';
import ProfitReport from './profit-report/profit-report';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../configurations/global.config';
import axios from 'axios';

export interface OrderStats {
  delivery: {
    APPROVED: number;
    REQUESTED: number;
    IN_TRANSIT: number;
    DELIVERED: number;
    CANCELLED: number;
  };
  payment: {
    UNINVOICED: number;
    INVOICED_FOR_DRIVER: number;
    INVOICED_FOR_MERCHANT: number;
  };
  order_results_per_month: {
    delivered: number[];
    cancelled: number[];
  };
  orders_per_governorate: { governorate: string; total: number }[];
}

export interface WidgetProps {
  stats?: OrderStats;
  isLoading: boolean;
  errorMessage: string;
}

function Home() {
  const [stats, setStats] = useState<OrderStats>();
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  console.log(stats);

  useEffect(() => {
    const fetchAndSetStats = async () => {
      setIsLoading(true);
      try {
        const endpoint = `${API_ENDPOINT}/api/org/orders/stats/`;
        const response = await axios.get<OrderStats>(endpoint, config);
        console.log(response);
        if (response.statusText === 'OK') {
          setStats(response.data);
        } else {
          setMessage('Failed collect data');
        }
      } catch (e) {
        console.log(e);
        setMessage('Failed to collect data');
      }
      setIsLoading(false);
    };
    fetchAndSetStats();
  }, []);

  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Main Dashboard
        </Typography>
        <Grid container spacing={2}>
          <ProfitReport />
          <DeliveryStatesWidget
            stats={stats}
            isLoading={isLoading}
            errorMessage={message}
          />
          <PaymentStatesWidget
            stats={stats}
            isLoading={isLoading}
            errorMessage={message}
          />
          <DeliveredOrdersHistoryWidget
            stats={stats}
            isLoading={isLoading}
            errorMessage={message}
          />
          <GovernorateDistributionWidget
            stats={stats}
            isLoading={isLoading}
            errorMessage={message}
          />
        </Grid>
      </Box>
    </div>
  );
}

export default Home;
