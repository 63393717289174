import { Alert, Box, LinearProgress, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';

import Section from './section';
import axios from 'axios';
import { OrganisationSettings } from '../../../interfaces/delivery-company/orders';
import { API_ENDPOINT } from '../../../configurations/global.config';
import Grid2 from '@mui/material/Unstable_Grid2';
import PickupLocationImage from './pickup-location-image/pickup-location-image';

export interface VersionedLogo {
  logoUrl: string;
  version: number;
}

function MerchantSettings() {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };



  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Settings
        </Typography>

        {loading ? (
          <LinearProgress />
        ) : (
          <>
            {error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <>
                <Grid2 spacing={2} container>
                  <Grid2 xs={12} md={6}>
                    <Section title="Pickup Location">
                      <PickupLocationImage />
                    </Section>
                  </Grid2>
                </Grid2>


              </>
            )}
          </>
        )}
      </Box>
    </div>
  );
}

export default MerchantSettings;
