import { filter } from 'lodash';
import { IFilterOption } from './types';

export const buildQueryParams = (filters: IFilterOption[]): string => {
  let queryParams = '';


  const aggregatedFilters = new Map<string, string[]>();

  filters.forEach((filter) => {
    const { key, value } = filter.toQuery();
    if (aggregatedFilters.has(key)) {
      aggregatedFilters.get(key)?.push(value);
    } else {
      aggregatedFilters.set(key, [value]);
    }
  });

  console.log("filtering = ", aggregatedFilters);

  Array.from(aggregatedFilters.keys()).forEach((key: string) => {
    const values = aggregatedFilters.get(key);
    if (!values) return;
    queryParams += `&${key}=${values.join(',')}`;
  });

  console.log("params = ", queryParams)
  return queryParams;
};
