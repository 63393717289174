import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  LinearProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  CircularProgress,
  TableRow,
  Typography,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import RestorePageIcon from '@mui/icons-material/RestorePage';

import { useNavigate, useParams } from 'react-router-dom';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FeedIcon from '@mui/icons-material/Feed';
import InventoryIcon from '@mui/icons-material/Inventory';
import HistoryIcon from '@mui/icons-material/History';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
  cardStyle,
  cardHeaderStyle,
  cardTableStyle,
} from '../../common/styles/grid-card.style';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import {
  showDeliveryState,
  showPaymentState,
} from '../../common/styles/order-states.style';
import ConfirmAction from '../../common/confirm-action/confirm-action';
import { AuthContext } from '../../../contexts/user-context/user-context';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { PermissionsContext } from '../../../contexts/user-context/permissions-context';
import { UserType } from '../../../enums/users';
import { ErrorsFlattenner } from '../../common/errors-flattenner/errors-flattenner';
import { get_cod } from '../../../utils/cod';
import { IInvoice } from '../../common/invoice-builder/Invoice';
import { InvoiceMetaType } from '../../../interfaces/common/invoice';
import InvoicePDFDialog from '../../common/invoice-pdf-dialog/invoice-pdf-dialog';
import { UserHasPermission } from '../../../utils/permissions';
import { useContext, useState } from 'react';
import { OrgThemeContext } from '../../../contexts/theme-context';
import { displayMoney } from '../../../utils/money';
import { displayDimensions } from '../../common/dimensions-input/utils';

const parseEvents = (eventsAsJson: string): string[] => {
  const objs: string[] = JSON.parse(eventsAsJson);
  return objs;
};

function Order() {
  const { orderId } = useParams();

  const [orderData, setOrderData] = React.useState<OrderType>();
  const [confirmDeleteOrder, setConfirmDeleteOrder] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<AxiosResponse>();
  const [message, setMessage] = React.useState<string>('');
  const [invoiceData, setInvoiceData] = React.useState<IInvoice | null>(null);
  const [lockedInvoiceMeta, setLockedInvoiceMeta] =
    React.useState<InvoiceMetaType | null>(null);
  const { user } = React.useContext(AuthContext);
  const { color } = useContext(OrgThemeContext);
  const [confirmUninvoiceOrder, setConfirmUninvoiceOrder] = useState(false);
  const [invoiceLockOperation, setInvoiceLockOperation] = useState<
    'view' | 'delete' | null
  >(null);

  const canViewInvoice =
    user?.type !== UserType.EMPLOYEE ||
    user?.permissions?.includes('view_invoicemeta');
  const canChangeInvoice =
    user?.type !== UserType.EMPLOYEE ||
    user?.permissions?.includes('change_invoicemeta');
  const canDeleteOrder =
    user?.type !== UserType.EMPLOYEE ||
    user?.permissions?.includes('delete_order');
  const canUpdateOrder =
    user?.type !== UserType.EMPLOYEE ||
    user?.permissions?.includes('change_order');

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  React.useEffect(() => {
    console.log('use effect');
    const getAndSetOrder = async () => {
      if (orderId !== undefined) {
        setIsLoading(true);
        try {
          const endpoint = `${API_ENDPOINT}/api/v1/order/${orderId}/`;
          console.log(endpoint);
          const response = await axios.get<OrderType>(endpoint, config);
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setOrderData(response.data);
          }
        } catch (e) {
          setOrderData(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load order data: ' + e.message);
          } else {
            setMessage('Failed to load order data');
          }
          console.log(e);
        }
        setIsLoading(false);
      }
    };
    getAndSetOrder();
  }, [orderId]);

  const navigate = useNavigate();

  const deleteOrder = async () => {
    if (orderData && orderData.id !== '') {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/v1/order/${orderData.id}/`,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setOrderData(undefined);
          setMessage('Order deleted successfully');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to delete order: ' + e.response?.statusText);
        } else {
          setMessage('Failed to delete order');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  const uninvoiceOrder = async () => {
    if (orderData) {
      setInvoiceLockOperation('delete');
      if (orderData.merchant_invoice)
        setLockedInvoiceMeta(orderData.merchant_invoice);
      else if (orderData.driver_invoice)
        setLockedInvoiceMeta(orderData.driver_invoice);
      const endpoint = `${API_ENDPOINT}/api/v1/order/uninvoice/${encodeURIComponent(orderData?.id ?? '')}/`;
      try {
        await axios.put(endpoint, {}, config);
        if (orderData.merchant_invoice) {
          setOrderData({ ...orderData, merchant_invoice: undefined });
        } else {
          setOrderData({ ...orderData, driver_invoice: undefined });
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to uninvoice order: ' + e.response?.statusText);
        } else {
          setMessage('Failed to uninvoice order');
        }
      }
      setInvoiceLockOperation(null);
      setLockedInvoiceMeta(null);
    }
  };

  const fetchInvoiceContent = async (
    invoicemeta: InvoiceMetaType | undefined,
    target: 'driver' | 'merchant'
  ) => {
    if (!invoicemeta || !orderData) return;
    setLockedInvoiceMeta(invoicemeta);
    setInvoiceLockOperation('view');

    const target_pk =
      target === 'driver' ? orderData.driver?.id : orderData.merchant.id;

    const endpoint = `${API_ENDPOINT}/api/org/${target}/${target_pk ?? ''}/invoice/${encodeURIComponent(invoicemeta.invoice_id)}/`;
    console.log(endpoint);

    try {
      const response = await axios.get(endpoint, config);
      setResponse(response);
      const data: IInvoice = response.data;
      data.orders.forEach((order) => (order.order_id = order.id));
      setInvoiceData(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setResponse(e.response);
        setMessage(
          'Failed to download invoice content: ' + e.response?.statusText
        );
      } else {
        setMessage('Failed to download invoice content');
      }
    }
    setLockedInvoiceMeta(null);
    setInvoiceLockOperation(null);
  };

  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Order Details
        </Typography>

        {isLoading && <LinearProgress />}

        {message !== '' && (
          <>
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              <>
                {message}

                {response && response.status >= 300 && (
                  <ErrorsFlattenner rawErrors={response.data} />
                )}
              </>
            </Alert>

            <br />
          </>
        )}

        {!isLoading && orderData && (
          <>
            <Grid container spacing={2}>
              {/* General Information */}
              <Grid xs={12} md={4}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <InventoryIcon />
                    <div>
                      <b>General Information</b>
                    </div>
                  </div>

                  <table style={cardTableStyle}>
                    <tbody>
                      <tr>
                        <th>Order Id</th>
                        <td>{orderData.order_id}</td>
                      </tr>

                      <tr>
                        <th>Reference Id</th>
                        <td>{orderData.reference_id}</td>
                      </tr>
                      <tr>
                        <th>Customer</th>
                        <td>
                          <span
                            style={{
                              cursor: 'pointer',
                              display: 'inline-block',
                            }}
                            onClick={() =>
                              navigate(`/customer/${orderData.customer?.id}`)
                            }
                          >
                            <b>{orderData.customer?.name} {orderData.customer?.phone_number}</b>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Merchant</th>
                        <td>
                          {' '}
                          <span
                            style={{
                              cursor: 'pointer',
                              display: 'inline-block',
                            }}
                            onClick={() =>
                              navigate(`/merchant/${orderData.merchant.id}`)
                            }
                          >
                            <b>{orderData.merchant.name}</b>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>{orderData.address}</td>
                      </tr>
                      <tr>
                        <th>Region</th>
                        <td>
                          {orderData.governorate}/{orderData.district}
                        </td>
                      </tr>
                      <tr>
                        <th>Pickup Location</th>
                        <td>
                          {orderData.pickup_location}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </Grid>

              <Grid xs={12} md={4}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <FeedIcon />
                    <div>
                      <b>Extra Information</b>
                    </div>
                  </div>
                  <table style={cardTableStyle}>
                    <tr>
                      <th>Number of packages</th>
                      <td>{orderData.number_of_packages}</td>
                    </tr>

                    <tr>
                      <th>Total Weight</th>
                      <td>{orderData.weight.toString()} kg</td>
                    </tr>

                    <tr>
                      <th>Dimensions</th>
                      <td>{displayDimensions(orderData.dimensions)} cm³</td>
                    </tr>
                    <tr>
                      <th>Branch</th>
                      <td>
                        <span
                          style={{ cursor: 'pointer', display: 'inline-block' }}
                          onClick={() =>
                            navigate(`/branch/${orderData.branch?.id}`)
                          }
                        >
                          <b>{orderData.branch?.name}</b>
                        </span>
                      </td>
                    </tr>
                    {orderData.description !== '' && (
                      <tr>
                        <th>Description</th>
                        <td>{orderData.description}</td>
                      </tr>
                    )}

                    {orderData.public_note !== '' && (
                      <tr>
                        <th>Public Note</th>
                        <td>{orderData.public_note}</td>
                      </tr>
                    )}

                    {orderData.private_note !== '' && (
                      <tr>
                        <th>Private Note</th>
                        <td>{orderData.private_note}</td>
                      </tr>
                    )}
                    <tr>
                      <th>Driver</th>
                      <td>
                        {orderData.driver ? (
                          <span
                            style={{
                              cursor: 'pointer',
                              display: 'inline-block',
                            }}
                            onClick={() =>
                              navigate(`/driver/${orderData.driver?.id}`)
                            }
                          >
                            <b>{orderData.driver?.name}</b>
                          </span>
                        ) : (
                          'not specified'
                        )}
                      </td>
                    </tr>
                    {orderData.driver_note !== '' && (
                      <tr>
                        <th>Driver Note</th>
                        <td>{orderData.driver_note}</td>
                      </tr>
                    )}

                    <tr>
                      <th>Delivery Status</th>
                      <td>{showDeliveryState(orderData.delivery_state)}</td>
                    </tr>

                    <tr>
                      <th>Labels</th>
                      <td>
                        {orderData.labels &&
                          orderData.labels.map((label) => (
                            <Chip
                              key={label.id}
                              label={`${label.key}: ${label.value}`}
                              sx={{ margin: 0.5 }}
                            />
                          ))}
                      </td>
                    </tr>
                  </table>
                </Card>
              </Grid>
              {/* Pricing Information */}
              <Grid xs={12} md={4}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <MonetizationOnIcon />
                    <div>
                      <b>Pricing</b>
                    </div>
                  </div>
                  <table style={cardTableStyle}>
                    <tr>
                      <th>COD</th>
                      <td>
                        {displayMoney(get_cod(orderData))}
                      </td>
                    </tr>
                    <tr>
                      <th>Price due to merchant</th>
                      <td>
                        {displayMoney(orderData.price)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Delivery fee due{' '}
                        {orderData.delivery_fee_covered_by_merchant
                          ? 'merchant'
                          : 'customer'}
                      </th>
                      <td>
                        {displayMoney(orderData.delivery_fee)}
                      </td>
                    </tr>
                    <tr>
                      <th>Driver Commission</th>
                      <td>
                        {displayMoney(orderData.driver_commission)}
                      </td>
                    </tr>
                    <tr>
                      <th>Payment Status</th>
                      <td>{showPaymentState(orderData.payment_state)}</td>
                    </tr>

                    {orderData.driver_invoice && (
                      <tr>
                        {canViewInvoice /*|| canUpdateOrder*/ && (
                          <th>Driver invoice</th>
                        )}
                        <td>
                          {canViewInvoice && (
                            <Button
                              onClick={() =>
                                fetchInvoiceContent(
                                  orderData.driver_invoice,
                                  'driver'
                                )
                              }
                              size="small"
                              disabled={lockedInvoiceMeta != null}
                            >
                              View
                              {lockedInvoiceMeta?.id ===
                                orderData.driver_invoice.id &&
                                invoiceLockOperation === 'view' ? (
                                <CircularProgress />
                              ) : (
                                <PreviewIcon fontSize="large" />
                              )}
                            </Button>
                          )}

                          {canUpdateOrder &&
                            canChangeInvoice &&
                            !orderData.merchant_invoice && (
                              <Button
                                size="small"
                                style={{ color: 'red' }}
                                onClick={() => setConfirmUninvoiceOrder(true)}
                                disabled={
                                  orderData.merchant_invoice != undefined
                                }
                              >
                                Uninvoice
                                {invoiceLockOperation === 'delete' ? (
                                  <CircularProgress />
                                ) : (
                                  <RestorePageIcon
                                    fontSize="large"
                                    style={{ color: 'red' }}
                                  />
                                )}
                              </Button>
                            )}
                        </td>
                      </tr>
                    )}

                    {orderData.merchant_invoice && (
                      <tr>
                        {canViewInvoice && <th>Merchant invoice</th>}
                        <td>
                          {canViewInvoice && (
                            <Button
                              onClick={() =>
                                fetchInvoiceContent(
                                  orderData.merchant_invoice,
                                  'merchant'
                                )
                              }
                              size="small"
                              disabled={lockedInvoiceMeta != null}
                            >
                              View
                              {lockedInvoiceMeta?.id ===
                                orderData.merchant_invoice.id &&
                                invoiceLockOperation === 'view' ? (
                                <CircularProgress />
                              ) : (
                                <PreviewIcon fontSize="large" />
                              )}
                            </Button>
                          )}

                          {canUpdateOrder && canChangeInvoice && (
                            <Button
                              size="small"
                              style={{ color: 'red' }}
                              onClick={() => setConfirmUninvoiceOrder(true)}
                              disabled={lockedInvoiceMeta != null}
                            >
                              Uninvoice
                              {invoiceLockOperation === 'delete' ? (
                                <CircularProgress />
                              ) : (
                                <RestorePageIcon
                                  fontSize="large"
                                  style={{ color: 'red' }}
                                />
                              )}
                            </Button>
                          )}
                        </td>
                      </tr>
                    )}
                  </table>
                </Card>
              </Grid>
              {/* State History */}
              <Grid xs={12} md={6}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <HistoryIcon />
                    <div>
                      <b>State History</b>
                    </div>
                  </div>
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Timestamp</b>
                          </TableCell>
                          <TableCell>
                            <b>Delivery State</b>
                          </TableCell>
                          <TableCell>
                            <b>Payment State</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {orderData.statestamps && (
                        <>
                          {orderData.statestamps.map((stateItem) => (
                            <TableRow
                              key={stateItem.timestamp}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {new Date(stateItem.timestamp).toLocaleString()}
                              </TableCell>
                              <TableCell>
                                {showDeliveryState(stateItem.delivery_state)}
                              </TableCell>
                              <TableCell>
                                {showPaymentState(stateItem.payment_state)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              {/* Events */}
              <Grid xs={12} md={6}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle(color)}>
                    <TimelineIcon />
                    <div>
                      <b>Events</b>
                    </div>
                  </div>
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Timestamp</b>
                          </TableCell>
                          <TableCell>
                            <b>Actor</b>
                          </TableCell>
                          <TableCell>
                            <b>Actions</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {orderData.events && (
                        <>
                          {orderData.events.map((event) => (
                            <TableRow
                              key={event.timestamp}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {new Date(event.timestamp).toLocaleString()}
                              </TableCell>
                              <TableCell scope="row">{event.actor}</TableCell>
                              <TableCell>
                                <ul>
                                  {parseEvents(event.description).map(
                                    (action) => (
                                      <li key={action}>{action}</li>
                                    )
                                  )}
                                </ul>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>

            <br />

            <div style={{ display: 'flex', gap: 7 }}>
              <Button
                onClick={() => navigate(`/edit-order/${orderData.id}`)}
                disabled={!canUpdateOrder}
                variant="contained"
              >
                Edit Order
              </Button>

              <Button
                onClick={() => {
                  setConfirmDeleteOrder(true);
                }}
                disabled={!canDeleteOrder}
                variant="outlined"
                color="error"
              >
                Delete Order
              </Button>
            </div>
          </>
        )}
      </Box>
      <ConfirmAction
        message={'Are you sure you want to delete this order?'}
        action={deleteOrder}
        isOpen={confirmDeleteOrder}
        handleClose={() => setConfirmDeleteOrder(false)}
      >
        <div>Attention: This action can not be undone</div>
      </ConfirmAction>

      <ConfirmAction
        message={'Are you sure you want to uninvoice this order?'}
        action={uninvoiceOrder}
        isOpen={confirmUninvoiceOrder}
        handleClose={() => setConfirmUninvoiceOrder(false)}
      >
        <div>Attention: This action can not be undone</div>
      </ConfirmAction>

      {invoiceData !== null && (
        <InvoicePDFDialog
          pdfData={invoiceData}
          onClose={() => {
            setInvoiceData(null);
          }}
        />
      )}
    </div>
  );
}

export default Order;
