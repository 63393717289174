import React, { useContext, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { BulkActionProps } from './props.interface';
import { FormControl, Button, TextField } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import WaybillPrinterMinified from '../waybill-printer/waybill-printer-minified';
import { AuthContext } from '../../../contexts/user-context/user-context';
import WaybillPrinterExpanded from '../waybill-printer/waybill-printer-expanded';

function PrintWaybills({ selectedOrders }: BulkActionProps) {
  const fileName = 'waybills';
  const { user } = useContext(AuthContext);
  const orgId = user?.organisation?.org_id ?? 'UNKNOWN';

  return (
    <FormControl fullWidth>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {
          <PDFDownloadLink
            document={
              <WaybillPrinterExpanded
                orgId={orgId}
                orders={Array.from(selectedOrders.values())}
              />
            }
            fileName={fileName}
          >
            {({ blob, url, loading, error }) => {
              return loading ? (
                <Button color="primary" size="large" disabled>
                  Loading...
                </Button>
              ) : (
                <Button color="primary" size="large">
                  <DownloadIcon fontSize="large" />
                </Button>
              );
            }}
          </PDFDownloadLink>
        }
      </div>
    </FormControl>
  );
}

export default PrintWaybills;
