import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
import QRCode from "qrcode";
import { OrderType } from "../../../interfaces/delivery-company/orders";
import { get_cod } from "../../../utils/cod";
import { displayMoney, isNonZero } from "../../../utils/money";
import { format } from "date-fns";


const styles = StyleSheet.create({
    page: { padding: 25, fontSize: 12, fontFamily: "Helvetica", backgroundColor: "#fff" },
    section: { marginBottom: 5, padding: 20, backgroundColor: "#fff", borderRadius: 10, boxShadow: "0px 4px 8px rgba(0,0,0,0.15)" },
    header: { fontSize: 13, fontWeight: "bold", marginBottom: 10, textAlign: "center", color: "#222", textTransform: "uppercase", borderBottomWidth: 3, borderBottomColor: "#333", paddingBottom: 10, letterSpacing: 1 },
    pageHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 20,
    },
    logo: {
        height: 55,
    },
    contactInfo: { fontSize: 12, marginBottom: 8, color: "#444" },
    table: { display: "flex", flexDirection: "column", borderWidth: 1, borderColor: "#aaa", borderRadius: 6, overflow: "hidden", backgroundColor: "#fafafa" },
    row: { flexDirection: "row", borderBottomWidth: 1, borderBottomColor: "#ccc", backgroundColor: "#fff" },
    cell: { flex: 1, padding: 12, fontSize: 12, textAlign: "center", color: "#333" },
    qrCell: { flex: 1, padding: 12, alignItems: "center", justifyContent: "center" },
    section_title: { color: "#000", margin: "auto", marginBottom: 5, fontSize: 14 },
    footer: { marginTop: 10, textAlign: "center", fontSize: 12, color: "#555", borderTopWidth: 1, borderTopColor: "#bbb", paddingTop: 12 },
});

const generateQRCode = async (text: string) => {
    try {
        return await QRCode.toDataURL(text);
    } catch (err) {
        console.error(err);
        return "";
    }
};

interface WaybillProps {
    orders: OrderType[];
    orgId: string;
}

interface OnePagerProps {
    order: OrderType;
    orgId: string;
}

const OnePager = ({ order, orgId }: OnePagerProps) => {
    const [qrCode, setQRCode] = useState("");

    useEffect(() => {
        generateQRCode(order.order_id.toString()).then(setQRCode);
    }, [order.order_id]);

    return (
        <Page size="A4" style={styles.page} wrap={false}>
            <View style={styles.pageHeader}>

                <Image
                    style={styles.logo}
                    src={`https://parcel-tracer-public.s3.eu-central-1.amazonaws.com/${orgId}/logo.png`}
                />

                <View style={styles.qrCell}>
                    {qrCode && <Image style={{ width: 90, height: 90 }} src={qrCode} />}
                </View>
            </View>

            <View style={styles.section}>
                <View style={styles.table}>
                    <View style={[styles.row, { backgroundColor: "#e2e6ea" }]}>
                        <Text style={[styles.cell,]}>General Information</Text>
                        <Text style={[styles.cell,]}>Ship From</Text>
                        <Text style={[styles.cell,]}>Ship To</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.cell}>
                            <Text>Order #{order.order_id}{"\n\n"}</Text>
                            <Text>Date: {format(
                                new Date(order.creation_date ?? ""),
                                'dd-MM-yyyy'
                            )}  {"\n\n"}</Text>
                            <Text>{isNonZero(get_cod(order)) ? "Cash on Delivery" : "PAID"} </Text>
                        </Text>
                        <Text style={styles.cell}>
                            <Text>{order.merchant.name}{"\n\n"}</Text>
                            <Text>{order.merchant.phone_number}</Text>
                        </Text>
                        <Text style={styles.cell}>
                            <Text>{order.customer?.name}{"\n\n"}</Text>
                            <Text>{order.customer?.address}{"\n\n"}</Text>
                            <Text>{order.customer?.phone_number}</Text>
                        </Text>
                    </View>
                </View>
            </View>

            <View style={styles.section} wrap={false}>
                <View style={styles.table}>
                    <View style={[styles.row, { backgroundColor: "#e2e6ea" }]}>
                        <Text style={[styles.cell,]}># Packages</Text>
                        <Text style={[styles.cell,]}>Note</Text>
                        <Text style={[styles.cell,]}>Description</Text>
                        <Text style={[styles.cell,]}>Total Amount</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.cell}>{order.number_of_packages}</Text>
                        <Text style={styles.cell}>{order.public_note}</Text>
                        <Text style={styles.cell}>{order.description}</Text>
                        <Text style={styles.cell}>{displayMoney(get_cod(order))}</Text>
                    </View>
                </View>
            </View>


            <View style={styles.footer} wrap={false}>
                <Text>Thank you for choosing our services.</Text>
            </View>
        </Page>
    );

}

const Waybill = ({ orders, orgId }: WaybillProps) => {
    return (
        <Document>
            {
                orders.map(order => <OnePager key={order.id} order={order} orgId={orgId} />)
            }
        </Document>
    );
};

export default Waybill;
