import MopedIcon from '@mui/icons-material/Moped';
import PendingIcon from '@mui/icons-material/Pending';
import VerifiedIcon from '@mui/icons-material/Verified';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import WarningIcon from '@mui/icons-material/Warning';
import { DeliveryState } from '../../enums/orders';
import {
  StateStamp
} from '../../interfaces/delivery-company/orders';

interface Step {
  label: string;
  icon: JSX.Element;
  timestamp?: string;
}

const expectedNextState = (state: string): DeliveryState | undefined => {
  switch (state) {
    case DeliveryState.REQUESTED:
      return DeliveryState.APPROVED;
    case DeliveryState.APPROVED:
      return DeliveryState.IN_TRANSIT;
    case DeliveryState.IN_TRANSIT:
      return DeliveryState.DELIVERED;
  }

  return undefined;
};

const generateExpectedFutureStates = (
  stateStamp: StateStamp
): DeliveryState[] => {
  const result: DeliveryState[] = [];
  let state = stateStamp.delivery_state.toString();
  let nextState = expectedNextState(state);
  while (nextState) {
    result.push(nextState);
    state = nextState;
    nextState = expectedNextState(state);
  }

  return result;
};

const getStateIcon = (
  state: DeliveryState,
  done: boolean,
  error: boolean
): JSX.Element => {
  let color = 'grey';

  if (done) color = error ? 'red' : 'green';

  const iconStyle = { style: { color: color, fontSize: '40' } };
  console.log(state);

  switch (state) {
    case DeliveryState.REQUESTED:
      return <PendingIcon {...iconStyle} />;
    case DeliveryState.APPROVED:
      return <VerifiedIcon {...iconStyle} />;
    case DeliveryState.IN_TRANSIT:
      return <MopedIcon {...iconStyle} />;
    case DeliveryState.DELIVERED:
      return <VolunteerActivismIcon {...iconStyle} />;
    case DeliveryState.CANCELLED:
      return <WarningIcon {...iconStyle} />;
  }
};

export const generateStateTrace = (state_history: StateStamp[]): Step[] => {

  const result: Step[] = [];

  const compact_state_history: StateStamp[] = []

  if (state_history.length > 0) {
    let prev_state = state_history[0]
    compact_state_history.push(prev_state)

    for (let i = 1; i < state_history.length; i++) {
      if (state_history[i].delivery_state != prev_state.delivery_state) {
        prev_state = state_history[i];
        compact_state_history.push(prev_state)
      }
    }
  }

  compact_state_history.forEach((stateStamp) => {
    result.push({
      icon: getStateIcon(
        stateStamp.delivery_state,
        true,
        stateStamp.delivery_state === DeliveryState.CANCELLED
      ),
      label: stateStamp.delivery_state,
      timestamp: stateStamp.timestamp,
    });
  });

  const futureStates = generateExpectedFutureStates(
    state_history[state_history.length - 1]
  );

  futureStates.forEach((state) => {
    result.push({
      icon: getStateIcon(state, false, false),
      label: state,
    });
  });

  console.log('result = ', result);
  return result;
};
