import Decimal from 'decimal.js';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { parseFloatWithPrecision } from '../../../utils/decimal';
import { add, Money } from '../../../utils/money';

export const compute_total_price = (orders: OrderType[]): Money[] => {
  let total_price: Money[] = [];
  orders.forEach(order => { total_price = add(total_price, order.price) })
  return total_price;
}

export const compute_total_delivery_fee = (orders: OrderType[]): Money[] => {
  let total_delivery_fee: Money[] = [];
  orders.forEach(order => { total_delivery_fee = add(total_delivery_fee, order.delivery_fee) })
  return total_delivery_fee;
}

export const compute_total_driver_commission = (orders: OrderType[]): Money[] => {
  let total_driver_commission: Money[] = [];
  orders.forEach(order => { total_driver_commission = add(total_driver_commission, order.driver_commission) })
  return total_driver_commission;
}