import React, { useContext } from 'react';
import { OrdersSummary } from './profit-report';
import { PageFooter } from '../../../common/pdf-builders-utils/page-footer';
import { titleStyles } from '../../../common/pdf-builders-utils/styles';
import { View, Text } from '@react-pdf/renderer';
import { CountsHistogram } from './counts-histogram';
import { OrgThemeContext } from '../../../../contexts/theme-context';

interface SummaryStatsAndChartsProps {
  title: string;
  ordersSummary: OrdersSummary;
  color: string;
}

function SummaryStatsAndCharts({
  ordersSummary,
  title,
  color,
}: SummaryStatsAndChartsProps) {
  return (
    <View wrap={false}>
      <PageFooter color={color} />
      <View style={{ ...titleStyles.titleContainer, marginTop: 10 }}>
        <Text style={titleStyles.sectionTitle}>{title}</Text>
      </View>
      {CountsHistogram(ordersSummary)}
    </View>
  );
}

export default SummaryStatsAndCharts;
