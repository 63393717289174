import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  LinearProgress,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ClearIcon } from '@mui/x-date-pickers';
import axios, { AxiosResponse } from 'axios';
import React, { useContext } from 'react';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { DeliveryState, PaymentState } from '../../../enums/orders';
import { DriverType } from '../../../interfaces/delivery-company/drivers';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import AutocompleteWithThrottle from '../autocomplete-with-throttle/autocomplete-with-throttle';
import { BulkActionProps } from './props.interface';
import { ErrorsFlattenner } from '../errors-flattenner/errors-flattenner';
import { parseFloatWithPrecision } from '../../../utils/decimal';
import Decimal from 'decimal.js';
import CreateOrEditDriver from '../../delivery-company-components/drivers/driver-forms/create-or-edit-driver';
import { Money } from '../../../utils/money';
import MoneyInput from '../money-input/money-input';



function SetDriverAction({ selectedOrders, bulkEndpoint, refetchOrders }: BulkActionProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [driver, setDriver] = React.useState<DriverType | null>(null);
  const [commission, setCommission] = React.useState<Money[]>([])
  const [creatingNewDriverOpen, setCreatingNewDriverOpen] =
    React.useState<boolean>(false);
  const [response, setResponse] = React.useState<AxiosResponse>();
  const [message, setMessage] = React.useState<string>('');

  const { user } = useContext(AuthContext);
  const orgId = user?.organisation?.org_id;

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleSubmit = async () => {
    const has_unapproved_orders = Array.from(selectedOrders.values()).some(
      (order) => order.delivery_state === DeliveryState.REQUESTED
    );
    if (has_unapproved_orders) {
      setMessage('Some of the orders you selected are not yet approved');
      setIsLoading(false);
      return;
    }

    const has_invoiced_orders = Array.from(selectedOrders.values()).some(
      (order) => order.payment_state !== PaymentState.UNINVOICED
    );
    if (has_invoiced_orders) {
      setMessage('Some of the orders you selected are already invoiced');
      setIsLoading(false);
      return;
    }

    let endpoint = bulkEndpoint;
    if (orgId) {
      endpoint += `?org=${encodeURIComponent(orgId)}`;
    }
    setIsLoading(true);
    setMessage('');
    setResponse(undefined);
    const requestData = Array.from(selectedOrders.keys()).map((order_pk) => {
      const order = {
        id: order_pk,
        driver: driver,
        delivery_state: DeliveryState.IN_TRANSIT,
        driver_commission: commission,
      };
      return order;
    });
    try {
      const response = await axios.patch<OrderType[]>(
        endpoint,
        requestData,
        config
      );
      setResponse(response);
      if (response.statusText == 'OK') {
        setMessage('Orders updated successfully');
        refetchOrders();
      } else {
        setMessage('Failed to update orders');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setResponse(e.response);
        setMessage('Failed to update orders: ' + e.response?.statusText);
      } else {
        setMessage('Failed to update orders');
      }
    }
    setIsLoading(false);
  };
  const handleFetchDrivers = async (query: string): Promise<DriverType[]> => {
    const endpoint = `${API_ENDPOINT}/api/v1/drivers/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

    try {
      const response = await axios.get<{
        count: number;
        results: DriverType[];
      }>(endpoint, config);

      if (response.statusText === 'OK') {
        return response.data.results.filter((driver) => !driver.is_deleted);
      }

      return [];
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setResponse(e.response);
        setMessage('Failed to fetch driver: ' + e.response?.statusText);
      } else {
        setMessage('Failed to fetch driver');
      }
    }

    return [];
  };

  return isLoading ? (
    <LinearProgress />
  ) : (
    <>
      <FormControl fullWidth>
        <AutocompleteWithThrottle
          label={'Driver'}
          required={false}
          onClickAdd={() => setCreatingNewDriverOpen(true)}
          fetch={handleFetchDrivers}
          value={driver ?? null}
          getOptionLabel={(option: DriverType) =>
            option.name + ' ' + option.phone_number
          }
          onChange={(e, value) => {
            setDriver(value);

            if (value) {
              setCommission(value.default_commission);
            }
          }}
        />
      </FormControl>
      <br></br>
      <br></br>

      {driver && driver.id !== '' && (
        <>
          <MoneyInput money={commission} setMoney={(m) => setCommission(m)} label='Driver Commission' />
          <br />
        </>
      )}

      {message !== '' ? (
        <>
          <Alert
            severity={
              response && response.status >= 200 && response.status < 300
                ? 'success'
                : 'error'
            }
          >
            <>
              {message}

              {response && response.status >= 300 && (
                <ErrorsFlattenner rawErrors={response.data} />
              )}
            </>
          </Alert>
          <br />
        </>
      ) : (
        <>
          {' '}
          <br />
        </>
      )}

      <FormControl>
        <Button
          variant="contained"
          disabled={!driver}
          type="submit"
          onClick={() => driver && handleSubmit()}
        >
          {' '}
          Apply{' '}
        </Button>
      </FormControl>

      <Dialog fullWidth open={creatingNewDriverOpen}>
        <div
          style={{
            textAlign: 'right',
            width: '100%',
          }}
        >
          <IconButton onClick={() => setCreatingNewDriverOpen(false)}>
            <ClearIcon color="primary" fontSize="large" />
          </IconButton>
        </div>

        <DialogContent>
          <CreateOrEditDriver />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SetDriverAction;

/*


                */
