import ClearIcon from '@mui/icons-material/Clear';
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  LinearProgress,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useContext, useEffect, useState } from 'react';

import {
  RegionType,
  emptyRegion,
  getGovernorateForDistrict,
  regions,
} from '../../../../data/regions';
import { OrderType } from '../../../../interfaces/delivery-company/orders';

import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { emptyCustomer } from '../../../../data/customers';
import { CustomerType } from '../../../../interfaces/common/customer';
import AutocompleteWithThrottle from '../../../common/autocomplete-with-throttle/autocomplete-with-throttle';
import { assignCustomerProvider } from '../../../common/create-callbacks-utils/create-callbacks-utils';
import CreateCustomerForm from '../../../common/create-customer-form/create-customer-form';
import TextFieldWithScan from '../../../common/textfield-with-scan/textfield-with-scan';
import { emptyOrder } from '../../../delivery-company-components/orders/create-order-form/create-order-form.utils';
import LabelsInput, {
  LabelValueWithKey,
} from '../../../common/labels-input/labels-input';
import AddressInputWithAutocomplete from '../../../common/adress-input-with-autocomplete/address-input-with-autocomplete';
import { ErrorsFlattenner } from '../../../common/errors-flattenner/errors-flattenner';
import { parseFloatWithPrecision } from '../../../../utils/decimal';
import Decimal from 'decimal.js';
import MoneyInput from '../../../common/money-input/money-input';
import { Money } from '../../../../utils/money';
import { get_price } from '../../../../utils/cod';
import WeightInput from '../../../common/weight-input/weight-input';
import DimensionsInput from '../../../common/dimensions-input/dimensions-input';

interface MerchantCreateOrderFormProps {
  defaultOrder?: OrderType;
}

function MerchantCreateOrderForm() {
  const [orderData, setOrderData] = useState<OrderType | undefined>(emptyOrder);
  const setSelectedLabels = (selectedLabels: LabelValueWithKey[]) => {
    if (orderData) setOrderData({ ...orderData, labels: selectedLabels });
  };

  const [creatingNewCustomerOpen, setCreatingNewCustomerOpen] =
    useState<boolean>(false);

  const [regionSearchInput, setRegionSearchInput] = useState(
    orderData?.district
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');
  const [autoAssignOrderId, setAutoAssignOrderId] = useState<boolean>(false);
  const [cod, setCod] = useState<Money[]>([])

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (orderData)
      setOrderData({
        ...orderData,
        delivery_fee: user?.default_delivery_fee ?? [],
        pickup_location: user?.address ?? "",
      });
  }, [user]);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleFetchCustomers = async (
    query: string
  ): Promise<CustomerType[]> => {
    if (orderData) {
      const endpoint = `${API_ENDPOINT}/api/merchant/customers/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

      try {
        const response = await axios.get<{
          count: number;
          results: CustomerType[];
        }>(endpoint, config);

        if (response.statusText === 'OK') {
          return response.data.results;
        }

        return [];
      } catch (error) {
        console.log(error);
      }
    }

    return [];
  };

  // Initialise data assigners to be used as success callbacks when creating entities inline
  const assignCustomer = assignCustomerProvider({
    orderData,
    setOrderData,
    setRegionSearchInput,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (orderData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Order...');

      const requestData = orderData;

      requestData.price = get_price(cod, requestData.delivery_fee, requestData.delivery_fee_covered_by_merchant)

      try {
        // create new
        const response = await axios.post(
          `${API_ENDPOINT}/api/v1/orders/`,
          requestData,
          config
        );

        setResponse(response);
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          setMessage('Order saved successfully');
          setOrderData({
            ...emptyOrder,
            merchant: orderData.merchant,
            delivery_fee: orderData.delivery_fee,
          });
          setRegionSearchInput('');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response);
          setMessage('Failed to save order: ' + e.response?.statusText);
        } else {
          setMessage('Failed to save order');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  return (
    <>
      <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
        {orderData?.id === '' ? 'Create Order' : 'Edit Order'}
      </Typography>

      {isLoading ? (
        <>
          {loadingMessage}
          <br />
          <LinearProgress />
        </>
      ) : (
        <>
          {message !== '' && (
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              <>
                {message}

                {response && response.status >= 300 && (
                  <ErrorsFlattenner rawErrors={response.data} />
                )}
              </>
            </Alert>
          )}

          {orderData && (
            <>
              <br></br>
              <Switch
                onChange={() => {
                  setAutoAssignOrderId(!autoAssignOrderId);
                  setOrderData({ ...orderData, order_id: '' });
                }}
                checked={autoAssignOrderId}
              />
              Assign Order Id Automatically
              <br />
              <br />
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    {!autoAssignOrderId && (
                      <Grid xs={12} md={6}>
                        <TextFieldWithScan
                          label="Order Id"
                          type="text"
                          variant="outlined"
                          fullWidth
                          required
                          disabled={autoAssignOrderId}
                          value={orderData.order_id}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                          }}
                          act={(value) =>
                            setOrderData({
                              ...orderData,
                              order_id: value.trim(),
                            })
                          }
                        />
                      </Grid>
                    )}

                    <Grid xs={12} md={autoAssignOrderId ? 12 : 6}>
                      <TextFieldWithScan
                        label="Reference Number"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={orderData.reference_id}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}
                        act={(value) =>
                          setOrderData({
                            ...orderData,
                            reference_id: value.trim(),
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <AddressInputWithAutocomplete
                    label='Pickup Location'
                    initialAddress={orderData.pickup_location}
                    callback={(addr, distr, govern) => {
                      setOrderData((prev) => {
                        return {
                          ...(prev ?? emptyOrder),
                          pickup_location: addr
                        };
                      });
                    }}
                    onBlur={(val) =>
                      setOrderData((prev) => {
                        return {
                          ...(prev ?? emptyOrder),
                          pickup_location: val,
                        };
                      })
                    }
                  />
                </FormControl>
                <br /><br />
                <FormControl fullWidth>
                  <AutocompleteWithThrottle
                    label={'Customer'}
                    onClickAdd={() => setCreatingNewCustomerOpen(true)}
                    fetch={handleFetchCustomers}
                    getOptionLabel={(option: CustomerType) =>
                      option.name + ' ' + option.phone_number
                    }
                    value={orderData.customer}
                    onChange={(e, value) =>
                      assignCustomer(value ?? emptyCustomer)
                    }
                  />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                      <AddressInputWithAutocomplete
                        label='Address'
                        initialAddress={orderData.address}
                        callback={(addr, distr, govern) => {
                          setOrderData((prev) => {
                            return {
                              ...(prev ?? emptyOrder),
                              address: addr,
                              district: distr,
                              governorate: govern,
                            };
                          });
                          setRegionSearchInput(distr);
                        }}
                        onBlur={(val) => {
                          setOrderData((prev) => {
                            return {
                              ...(prev ?? emptyOrder),
                              address: val,
                            };
                          });
                        }}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Autocomplete
                        disablePortal
                        id="district"
                        isOptionEqualToValue={(option, value) =>
                          option.district === value.district
                        }
                        value={
                          orderData.district !== ''
                            ? {
                              district: orderData.district,
                              governorate: orderData.governorate,
                            }
                            : null
                        }
                        onChange={(_, newValue: RegionType | null) => {
                          if (newValue === null) newValue = emptyRegion;

                          setOrderData({
                            ...orderData,
                            district: newValue.district,
                            governorate: newValue.governorate,
                          });
                        }}
                        inputValue={regionSearchInput}
                        onInputChange={(event, newInputValue) => {
                          if (event === null) return;
                          setRegionSearchInput(newInputValue);
                        }}
                        options={regions.sort((a, b) =>
                          b.governorate.localeCompare(a.governorate)
                        )}
                        groupBy={(option) => option.governorate}
                        getOptionLabel={(option) => option.district}
                        renderInput={(params) => (
                          <TextField {...params} label="District" />
                        )}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <br />
                <br />
                <MoneyInput money={cod} setMoney={(m) => setCod(m)} label='Collect on Delivery' />

                <br />

                <MoneyInput money={orderData.delivery_fee} setMoney={(m) => setOrderData({ ...orderData, delivery_fee: m })} label='Delivery Fee' />
                <br />
                <Switch
                  checked={orderData.delivery_fee_covered_by_merchant}
                  onChange={() =>
                    setOrderData({
                      ...orderData,
                      delivery_fee_covered_by_merchant:
                        !orderData.delivery_fee_covered_by_merchant,
                    })
                  }
                />
                <>Delivery fees covered by merchant</>
                <br /> <br />
                <Grid container spacing={2}>

                  <Grid xs={12} md={6}>   <FormControl fullWidth>
                    <TextField
                      label="Number of Packages"
                      type="number"
                      variant="outlined"
                      fullWidth
                      required
                      value={orderData.number_of_packages}
                      onFocus={(e) =>
                        e.target.addEventListener(
                          'wheel',
                          (e) => e.preventDefault(),
                          { passive: false }
                        )
                      }
                      onChange={(e) =>
                        setOrderData({
                          ...orderData,
                          number_of_packages: parseInt(e.target.value),
                        })
                      }
                    />  </FormControl>
                  </Grid>


                  <Grid xs={12} md={6}>
                    <WeightInput weight={orderData.weight} setWeight={(w) => setOrderData({ ...orderData, weight: w })} />
                  </Grid>
                </Grid>

                <br />
                <br />
                <FormControl fullWidth>
                  <DimensionsInput dimensions={orderData.dimensions} setDimensions={(d) => setOrderData({ ...orderData, dimensions: d })} />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <LabelsInput
                    currentlySelectedLabels={orderData.labels ?? []}
                    setCurrentlySelectedLabels={setSelectedLabels}
                  />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                  <TextField
                    label="Description"
                    type="text"
                    variant="outlined"
                    multiline
                    value={orderData.description}
                    rows={4}
                    onChange={(e) =>
                      setOrderData({
                        ...orderData,
                        description: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <br />
                <br />

                <FormControl fullWidth>
                  <TextField
                    label="Public Note"
                    type="text"
                    variant="outlined"
                    multiline
                    value={orderData.public_note}
                    rows={2}
                    onChange={(e) =>
                      setOrderData({
                        ...orderData,
                        public_note: e.target.value,
                      })
                    }
                  />
                </FormControl>
                <br />
                <br />
                <Button type="submit" variant="contained">
                  Save Order
                </Button>
              </form>
            </>
          )}
        </>
      )}

      <Dialog
        fullWidth
        open={creatingNewCustomerOpen}
        onClose={() => undefined}
      >
        <div
          style={{
            textAlign: 'right',
            width: '100%',
          }}
        >
          <IconButton onClick={() => setCreatingNewCustomerOpen(false)}>
            <ClearIcon color="primary" fontSize="large" />
          </IconButton>
        </div>

        <DialogContent>
          <CreateCustomerForm
            baseEndpoint={`${API_ENDPOINT}/api/merchant`}
            successCallback={assignCustomer}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MerchantCreateOrderForm;
