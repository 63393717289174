import React, { useState } from 'react';
import { Currencies, CURRENCY, get_precision, Money } from '../../../utils/money';
import { FormControl, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Box, IconButton, Button } from '@mui/material';
import Decimal from 'decimal.js';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { parseFloatWithPrecision } from '../../../utils/decimal';
import Grid2 from '@mui/material/Unstable_Grid2';

interface DimensionsInputProps {
    dimensions: Decimal[];
    setDimensions: (money: Decimal[]) => void;
}

function DimensionsInput({ dimensions, setDimensions }: DimensionsInputProps) {
    const handleDimensionChange = (index: number, value: string) => {
        const newVal = parseFloatWithPrecision(value).toDecimalPlaces(0)
        const newDimensions: Decimal[] = dimensions.map((current, i) => (i === index ? newVal : current));
        setDimensions(newDimensions)
    };

    const labels = ["Length", "Width", "Height"]
    return (
        <Box
            component="fieldset"
            sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                height: "100%",

            }}
        >
            <legend style={{ padding: 3, color: "#888" }}>Dimensions *</legend>

            <Grid2 spacing={2} container sx={{ padding: 2 }}>
                {dimensions.map((item, index) => (
                    <Grid2 key={index} xs={12} md={4}>
                        <TextField
                            fullWidth
                            onFocus={(e) =>
                                e.target.addEventListener(
                                    'wheel',
                                    (e) => e.preventDefault(),
                                    { passive: false }
                                )
                            }
                            label={`${labels[index]} (cm)`}
                            type="number"
                            value={item}
                            inputProps={{
                                maxLength: 12,
                                step: '1',
                                min: 0,
                            }}
                            onChange={(e) => { handleDimensionChange(index, e.target.value) }}
                            variant="outlined"
                        />
                    </Grid2>

                ))}

            </Grid2>


        </Box>
    );
}

export default DimensionsInput;
