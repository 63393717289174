import { useContext, useEffect, useState } from 'react';
import { Card, FormControl, TablePagination } from '@mui/material';
import SearchBar from '../../../common/search-bar/search-bar';

import MerchantsListingTable from './merchants-listing.table';
import { CompactMerchantType, MerchantType } from '../../../../interfaces/delivery-company/merchants';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/user-context/user-context';
import { API_ENDPOINT } from '../../../../configurations/global.config';
import { PaginationContext } from '../../../../contexts/pagination-context';
import AutocompleteWithThrottle from '../../../common/autocomplete-with-throttle/autocomplete-with-throttle';

function MerchantsListing() {
  const [merchantsList, setMerchantsList] = useState<MerchantType[]>([]);
  const [merchantsCount, setMerchantsCount] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [merchant, setMerchant] = useState<MerchantType | null>(null);

  const searchOptions = ['Name', 'Phone', 'Address'];

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const { paginationConfig, setPaginationConfig } =
    useContext(PaginationContext);

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: paginationConfig.rowsPerPage,
    searchColumn: 'Name',
    searchValue: '',
    searchOperation: 'equals',
  });

  useEffect(() => {
    const getData = async () => {
      let endpoint = `${API_ENDPOINT}/api/v1/merchants/?page=${controller.page + 1}&page_size=${controller.rowsPerPage}`;

      if (controller.searchColumn !== '' && controller.searchValue !== '') {
        endpoint += `&${controller.searchColumn.toLowerCase()}__${controller.searchOperation}=${encodeURIComponent(controller.searchValue)}`;
      }

      console.log(endpoint);
      try {
        const response = await axios.get<{
          count: number;
          results: MerchantType[];
        }>(endpoint, config);
        console.log(response);

        if (response.statusText === 'OK') {
          setMerchantsList(response.data.results);
          setMerchantsCount(response.data.count);
        } else {
          throw new Error('Request failed');
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    getData();
  }, [controller]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPaginationConfig({ ...paginationConfig, rowsPerPage: newRowsPerPage });
    setController({
      ...controller,
      rowsPerPage: newRowsPerPage,
      page: 0,
    });
  };

  const searchQueryAction = (
    column: string,
    value: string,
    operation: string
  ) => {
    setController({
      ...controller,
      page: 0,
      searchColumn: column,
      searchValue: value,
      searchOperation: operation,
    });
  };

  const handleFetchMerchants = async (
    query: string
  ): Promise<MerchantType[]> => {
    const endpoint = `${API_ENDPOINT}/api/v1/merchants/?page_size=10&name_or_phone=${encodeURIComponent(query)}`;

    try {
      const response = await axios.get<{
        count: number;
        results: MerchantType[];
      }>(endpoint, config);
      if (response.statusText === 'OK') {
        return response.data.results;
      }
      return [];
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  let displayedMerchants = merchantsList
  if (merchant)
    displayedMerchants = [merchant]

  return (
    <Card sx={{ padding: 2, overflow: 'auto' }}>
      <FormControl fullWidth>
        <AutocompleteWithThrottle
          label={'Search by name or phone number'}
          required={false}
          fetch={handleFetchMerchants}
          value={merchant}
          getOptionLabel={(option: CompactMerchantType) =>
            option.name + ' ' + option.phone_number
          }
          onChange={(e, value) => {
            setMerchant(value);
          }}
        />
      </FormControl>
      <br />
      <MerchantsListingTable merchants={displayedMerchants} isLoading={isLoading} />
      <TablePagination
        component="div"
        onPageChange={handlePageChange}
        page={controller.page}
        count={merchant ? 1 : merchantsCount}
        rowsPerPage={controller.rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 250]}
        labelRowsPerPage=""
        style={{ overflow: 'visible' }}
      />
    </Card>
  );
}

export default MerchantsListing;
