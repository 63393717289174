// Theme palette
export const primaryPaletteColor = '#805fa8'; // 128, 95, 168
export const secondaryPaletteColor = '#eee';
export const companyName = 'Parcel Tracer';

// Backend Endpoint
export const API_ENDPOINT = process.env.REACT_APP_API_ENPOINT;

////////////////////////////////
//                            //
//   Main Dashboard Styling   //
//                            //
////////////////////////////////

// Delivery States Metrics
export const requestedColor = '#ffd000';
export const approvedColor = '#126ec4';
export const inTransitColor = '#582197';
export const deliveredColor = '#047400';
export const cancelledColor = '#da0000';
export const totalColor = '#2b2222';

// Payment States Metrics
export const uninvoicedColor = requestedColor;
export const invoicedDriver = 'black';
export const invoicedMerchant = deliveredColor;

export const PALETTE = [
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
];
