import React, { useContext, useState } from 'react';
import { IInvoice } from '../invoice-builder/Invoice';
import {
  DialogContent,
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  FormControl,
} from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { isDesktop, isMobile } from 'react-device-detect';
import {
  defaultDriverFooter,
  defaultDriverReportFieldsConfig,
  defaultMerchantFooter,
  defaultMerchantReportFieldsConfig,
} from '../report-builder/fields-config';
import { ReportProps, ReportDocument } from '../report-builder/report';
import DownloadIcon from '@mui/icons-material/Download';
import { ClearIcon } from '@mui/x-date-pickers';
import { OrgThemeContext } from '../../../contexts/theme-context';
import { format } from 'date-fns';
import { populate_money_fields } from './utils';

interface InvoicePDFDialogProps {
  pdfData?: IInvoice | null;
  onClose?: () => void;
}

function InvoicePDFDialog({ pdfData, onClose }: InvoicePDFDialogProps) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { color } = useContext(OrgThemeContext);

  if (!pdfData) return <div>Failed to get invoice data</div>;
  const formattedDate = format(
    new Date(parseFloat(pdfData.timestamp) * 1000),
    'dd-MM-yyyy'
  );
  let filename = '';
  if (pdfData.driver) {
    filename = `${pdfData.invoice_id}.${pdfData.driver.name}.${formattedDate}`;
  } else if (pdfData.merchant) {
    filename = `${pdfData.invoice_id}.${pdfData.merchant.name}.${formattedDate}`;
  }
  console.log("orders = ", pdfData.orders)
  const reportDocProps: ReportProps = {
    color: color,
    tables: [
      {
        orders: populate_money_fields(pdfData.orders),
        fields_config: pdfData.driver
          ? defaultDriverReportFieldsConfig
          : defaultMerchantReportFieldsConfig,
        header_bg: '#eee',
        title: 'Orders',
        table_footer_rows: pdfData.merchant ? defaultMerchantFooter : defaultDriverFooter,
      },
    ],
    subtitles: [
      pdfData.driver
        ? `Driver: ${pdfData.driver.name} / ${pdfData.driver.phone_number}`
        : `Merchant: ${pdfData.merchant?.name} / ${pdfData.merchant?.phone_number}`,
    ],
    organisation_id: pdfData.organisation_id,
    date_from: format(
      new Date(parseFloat(pdfData.timestamp) * 1000),
      'dd-MM-yyyy'
    ),
    date_to: format(
      new Date(parseFloat(pdfData.timestamp) * 1000),
      'dd-MM-yyyy'
    ),
    title: `${pdfData.type} Invoice #${pdfData.invoice_id}`,
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <div
        style={{
          textAlign: 'right',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <DialogTitle>Invoice Ready 🎉</DialogTitle>
        <IconButton
          onClick={() => {
            onClose && onClose();
            setIsOpen(false);
          }}
        >
          <ClearIcon color="primary" fontSize="large" />
        </IconButton>
      </div>

      <DialogContent style={isDesktop ? { width: '100%', height: '80vh' } : {}}>
        {isMobile ? (
          <>
            {pdfData && (
              <PDFDownloadLink
                document={<ReportDocument {...reportDocProps} />}
                fileName={filename}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <LinearProgress />
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button variant="outlined">
                        Save to device{' '}
                        <DownloadIcon color={'primary'} fontSize="large" />
                      </Button>
                    </div>
                  )
                }
              </PDFDownloadLink>
            )}
          </>
        ) : (
          <>
            {pdfData && (
              <>
                <PDFDownloadLink
                  document={<ReportDocument {...reportDocProps} />}
                  fileName={filename}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <LinearProgress />
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <Button variant="outlined">
                          Save to device
                          <DownloadIcon color={'primary'} fontSize="large" />
                        </Button>
                      </div>
                    )
                  }
                </PDFDownloadLink>
                <br />
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                  <ReportDocument {...reportDocProps} />
                </PDFViewer>
              </>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default InvoicePDFDialog;
