import Decimal from 'decimal.js';
import { emptyMerchant } from '../../../../data/merchants';
import { DeliveryState, PaymentState } from '../../../../enums/orders';
import { OrderType } from '../../../../interfaces/delivery-company/orders';
import { CURRENCY } from '../../../../utils/money';

export const emptyOrder: OrderType = {
  id: '',
  order_id: '',
  reference_id: '',
  customer: null,
  address: '',
  district: '',
  governorate: '',
  branch: null,
  driver: null,
  merchant: emptyMerchant,
  number_of_packages: 1,
  description: '',
  delivery_state: DeliveryState.REQUESTED,
  payment_state: PaymentState.UNINVOICED,
  tracking_hash: '',
  private_note: '',
  public_note: '',
  driver_note: '',
  delivery_fee_covered_by_merchant: false,
  price: [],
  delivery_fee: [],
  driver_commission: [],
  pickup_location: "",
  weight: Decimal(0),
  dimensions: [Decimal(0), Decimal(0), Decimal(0)],
};
