import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import MuiPhoneNumber from 'mui-phone-number';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../../configurations/global.config';
import { UserType } from '../../enums/users';
import { ResetPasswordFormData } from '../../interfaces/common/authentication';
import { passwordValidationError } from './utils';
import { VerifyPhoneDialog } from './verify-phone-dialog';
import { format_phone_number } from '../../utils/phone_numbers';
import { MuiTelInput } from 'mui-tel-input';

const defaultFormData: ResetPasswordFormData = {
  userType: UserType.DRIVER,
  password: '',
  confirmPassword: '',
  phone: '',
};

function ResetPasswordForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<AxiosResponse>();
  const [formData, setFormData] =
    useState<ResetPasswordFormData>(defaultFormData);
  const [message, setMessage] = useState<string>('');
  const [verificationDialogOpen, setVerificationDialogOpen] =
    useState<boolean>(false);

  const supported_profiles = ['organisation'];

  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setResponse(undefined);
    setMessage('');
    setIsLoading(true);

    const passwordError = passwordValidationError(
      formData.password,
      formData.confirmPassword
    );

    if (passwordError) {
      setMessage(passwordError);
    } else {
      const phone = format_phone_number(formData.phone);
      if (phone === '') {
        setMessage('invalid phone number');
        setIsLoading(false);
        return;
      }
      const requestData = {
        account_id: phone,
        phone_number: phone,
        password: formData.password,
        account_type: formData.userType,
      };

      try {
        const response = await axios.post(
          `${API_ENDPOINT}/api/auth/change-password/`,
          requestData
        );
        setResponse(response);
        if (response.statusText === 'Created') {
          setVerificationDialogOpen(true);
        }
      } catch (e) {
        if (isAxiosError(e)) {
          const response = e.response;
          setResponse(response);
          if (response?.statusText === 'Not Found') {
            setMessage('This phone number is not associated to an account.');
          } else if (response?.data.message === 'account suspended') {
            setMessage(
              'Your account has been suspended because you entered a wrong verification code too many times. Please contact us to unblock your account.'
            );
          } else if (response?.data.message === 'too many requests') {
            setMessage(
              `Too many requests. Please wait 2 minutes and try again.`
            );
          } else {
            setMessage('Unexpected server error. Please contact us.');
          }
        } else {
          setMessage('Unexpected error. Please contact us.');
        }
      }
    }
    setIsLoading(false);
  };

  const closeVerificationDialog = () => {
    setVerificationDialogOpen(false);
  };

  return (
    <>
      {message !== '' && (
        <Alert severity={response?.statusText === 'OK' ? 'success' : 'error'}>
          {message}
        </Alert>
      )}
      <br></br>
      <form onSubmit={handleSubmit}>
        {/* User Type */}
        <FormControl key={'user-type-input'} fullWidth>
          <InputLabel id="user-type-select-label"> Profile</InputLabel>
          <Select
            labelId="user-type-select-label"
            id="entity-select"
            value={formData.userType}
            label="Entity"
            required
            disabled={isLoading}
            onChange={(e) =>
              setFormData({ ...formData, userType: e.target.value })
            }
          >
            {Object.values(UserType).map((key, index) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <br />
        <br />

        {supported_profiles.includes(formData.userType) ? (
          <>
            {/* Phone Number */}
            <FormControl key={'phone-input'} fullWidth>
              <MuiTelInput
                forceCallingCode
                label="Phone Number"
                disabled={isLoading}
                defaultCountry={'LB'}
                variant="outlined"
                required
                value={formData.phone}
                onChange={(newPhone) => {
                  if (typeof newPhone === 'string') {
                    setFormData({ ...formData, phone: newPhone });
                  }
                }}
              />
            </FormControl>

            <br />
            <br />

            {/* Password */}
            <FormControl key={'password-input'} fullWidth>
              <TextField
                id="outlined-password-input"
                label="New Password"
                type="password"
                value={formData.password}
                disabled={isLoading}
                required
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                autoComplete="current-password"
              />
            </FormControl>

            <br />
            <br />

            {/* Confirm Pass */}
            <FormControl key={'password-confrim-input'} fullWidth>
              <TextField
                id="password-confrim-input"
                label="Confirm New Password"
                type="password"
                value={formData.confirmPassword}
                disabled={isLoading}
                required
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
              />
            </FormControl>

            <br />
            <br />

            <Button fullWidth variant="contained" type="submit">
              Update Password
            </Button>
          </>
        ) : (
          <>
            {formData.userType === 'employee' && (
              <Alert severity="warning">
                Please ask you delivery organisation admin to change your
                password.
              </Alert>
            )}
            {formData.userType === 'driver' && (
              <Alert severity="warning">
                Please ask you delivery organisation admin to change your
                password.
              </Alert>
            )}
            {formData.userType === 'merchant' && (
              <Alert severity="warning">
                Please ask you delivery organisation admin to change your
                password.
              </Alert>
            )}
            {formData.userType === 'organisation' && (
              <Alert severity="warning">
                To change your password, please contact us at +33753560667
              </Alert>
            )}
          </>
        )}

        <hr />
        {isLoading && <LinearProgress />}

        <Button
          fullWidth
          style={{ display: 'inline-block', marginTop: 12 }}
          variant="outlined"
          disabled={isLoading}
          onClick={() => navigate('/login')}
        >
          Already have an account?
        </Button>
      </form>

      <VerifyPhoneDialog
        isOpen={verificationDialogOpen}
        closeVerificationDialog={closeVerificationDialog}
        phoneNumber={formData.phone}
        password={formData.password}
      />
    </>
  );
}

export default ResetPasswordForm;
