import Decimal from "decimal.js";
import { OrderType } from "../../../interfaces/delivery-company/orders";
import { CURRENCY } from "../../../utils/money";
import { IInvoice } from "../invoice-builder/Invoice";

export const populate_money_fields = (orders: OrderType[]): OrderType[] => {
    // used for backward compatibility
    const res: OrderType[] = []
    orders.forEach(order => {
        if (!order.price) {
            res.push({
                ...order,
                price: [[CURRENCY.USD, order.price_usd ?? Decimal(0)], [CURRENCY.LBP, order.price_lbp ?? Decimal(0)]],
                delivery_fee: [[CURRENCY.USD, order.delivery_fee_usd ?? Decimal(0)], [CURRENCY.LBP, order.delivery_fee_lbp ?? Decimal(0)]],
                driver_commission: [[CURRENCY.USD, order.driver_commission_usd ?? Decimal(0)], [CURRENCY.LBP, order.driver_commission_lbp ?? Decimal(0)]],
            })
        } else {
            res.push(order)
        }
    })
    return res
}