import { useContext } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { AuthContext } from '../../contexts/user-context/user-context';
import { UserType } from '../../enums/users';
import Authentication from '../authentication/authentication';
import PageNotFound from '../common/page-not-found/page-not-found';
import Accounting from '../delivery-company-components/accouting/accounting';
import Branch from '../delivery-company-components/branch/branch';
import Branches from '../delivery-company-components/branches/branches';
import Customer from '../delivery-company-components/customer/customer';
import Customers from '../delivery-company-components/customers/customers';
import DriverOrderAssigner from '../delivery-company-components/driver-order-assigner/driver-order-assigner';
import Driver from '../delivery-company-components/driver/driver';
import Drivers from '../delivery-company-components/drivers/drivers';
import EditOrder from '../delivery-company-components/edit-order/edit-order';
import Employee from '../delivery-company-components/employee/employee';
import Employees from '../delivery-company-components/employees/employees';
import Home from '../delivery-company-components/home/home';
import Merchant from '../delivery-company-components/merchant/merchant';
import Merchants from '../delivery-company-components/merchants/merchants';
import Order from '../delivery-company-components/order/order';
import Orders from '../delivery-company-components/orders/orders';
import DriverInvoices from '../driver-components/driver-invoices/driver-invoices';
import DriverOrderLookup from '../driver-components/driver-order-lookup/driver-order-lookup';
import MerchantEditOrder from '../merchant-components/merchant-edit-order/merchant-edit-order';
import MerchantInvoices from '../merchant-components/merchant-invoices/merchant-invoices';
import MerchantOrder from '../merchant-components/merchant-order/merchant-order';
import MerchantOrders from '../merchant-components/merchant-orders/merchant-orders';
import './main.css';
import LabelsListing from '../delivery-company-components/labels/labels-listing/labels-listing';
import Labels from '../delivery-company-components/labels/labels';
import Label from '../delivery-company-components/label/label';
import { UserHasPermission } from '../../utils/permissions';
import Settings from '../delivery-company-components/settings/settings';
import DriverOrders from '../driver-components/driver-orders/driver-orders';
import MerchantSettings from '../merchant-components/merchant-settings/settings';

const PrivateRoutes = () => {
  const { user } = useContext(AuthContext);
  if (user === null) return <Navigate to="/login" replace />;
  return <Outlet />;
};

const getDefaultRoute = (userType: string): string => {
  switch (userType) {
    case UserType.DRIVER:
      return '/driver-order-lookup';
    case UserType.EMPLOYEE:
      return '/orders';
    case UserType.MERCHANT:
      return '/merchant-orders';
    case UserType.ORGANISATION:
      return '/home';
    default:
      return '/';
  }
};

const ADMIN_ONLY: string[] = [UserType.ORGANISATION];
const EMPLOYEE_OR_ADMIN: string[] = [UserType.ORGANISATION, UserType.EMPLOYEE];
const MERCHANT_ONLY: string[] = [UserType.MERCHANT];
const DRIVER_ONLY: string[] = [UserType.DRIVER];

function MainContent() {
  const { user } = useContext(AuthContext);
  let userType = '';

  if (user) userType = user.type;
  else {
    return (
      <Routes>
        {/* Authentication routes */}
        <Route path="/login/:org" element={<Authentication mode="signin" />} />
        <Route path="/login" element={<Authentication mode="signin" />} />
        <Route path="/signup" element={<Authentication mode="signup" />} />
        <Route
          path="/reset-password"
          element={<Authentication mode="reset" />}
        />

        {/* Default route */}
        <Route path="*" element={<Navigate to={'/login'} replace />} />
      </Routes>
    );
  }

  return (
    <div className="main-content">
      <Routes>
        <Route path="/login" element={<Authentication mode="signin" />} />
        <Route path="/signup" element={<Authentication mode="signup" />} />
        <Route
          path="/reset-password"
          element={<Authentication mode="reset" />}
        />
        <Route element={<PrivateRoutes />}>
          {/* Default route */}
          <Route
            path="/"
            element={<Navigate to={getDefaultRoute(user.type)} replace />}
          />

          {(ADMIN_ONLY.includes(userType) || user.is_admin) && (
            <>
              {/* Home Dashboard */}
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />

              {/* Employees */}
              <Route path="/employees" element={<Employees />} />
              <Route path="/employee">
                <Route path=":employeeId" element={<Employee />} />
              </Route>

              {/* Branches */}
              <Route path="/branches" element={<Branches />} />
              <Route path="/branch">
                <Route path=":branchId" element={<Branch />} />
              </Route>

              {/* Settings */}
              <Route path="/settings" element={<Settings />} />
            </>
          )}

          {EMPLOYEE_OR_ADMIN.includes(userType) && (
            <>
              {/* Orders */}
              {UserHasPermission(user, 'order') && (
                <>
                  <Route path="/orders" element={<Orders />} />

                  <Route path="/order">
                    <Route path=":orderId" element={<Order />} />
                  </Route>
                  <Route path="/edit-order">
                    <Route path=":orderId" element={<EditOrder />} />
                  </Route>
                </>
              )}

              {/* Customers */}
              <Route path="/customers" element={<Customers />} />
              <Route path="/customer">
                <Route path=":customerId" element={<Customer />} />
              </Route>

              {/* Merchants */}
              {UserHasPermission(user, 'merchant') && (
                <>
                  <Route path="/merchants" element={<Merchants />} />

                  <Route path="/merchant">
                    <Route path=":merchantId" element={<Merchant />} />
                  </Route>
                </>
              )}

              {/* Labels */}
              {UserHasPermission(user, 'labelkey') && (
                <>
                  <Route path="/labels" element={<Labels />} />
                  <Route path="/label">
                    <Route path=":labelId" element={<Label />} />
                  </Route>
                </>
              )}

              {/* Drivers */}
              {UserHasPermission(user, 'driver') && (
                <>
                  <Route path="/drivers" element={<Drivers />} />
                  <Route path="/driver">
                    <Route path=":driverId" element={<Driver />} />
                  </Route>

                  <Route path="/driver-order-assigner">
                    <Route path=":driverId" element={<DriverOrderAssigner />} />
                  </Route>
                </>
              )}

              {/* Accounting */}
              {UserHasPermission(user, 'invoicemeta') && (
                <Route path="/accounting" element={<Accounting />} />
              )}
            </>
          )}

          {DRIVER_ONLY.includes(userType) && (
            <>
              <Route
                path="/driver-order-lookup"
                element={<DriverOrderLookup />}
              />
              <Route path="/driver-orders" element={<DriverOrders />} />
              <Route path="/invoices" element={<DriverInvoices />} />
            </>
          )}

          {MERCHANT_ONLY.includes(userType) && (
            <>
              <Route path="/merchant-orders" element={<MerchantOrders />} />
              <Route path="/invoices" element={<MerchantInvoices />} />
              <Route path="/settings" element={<MerchantSettings />} />
              <Route path="/org">
                <Route path=":orgId">
                  <Route path="order">
                    <Route path=":orderId" element={<MerchantOrder />} />
                  </Route>
                  <Route path="edit-order">
                    <Route path=":orderId" element={<MerchantEditOrder />} />
                  </Route>
                </Route>
              </Route>
              <Route path="/edit-order">
                <Route path=":orderId" element={<MerchantEditOrder />} />
              </Route>
            </>
          )}
        </Route>

        {/* Error route */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default MainContent;
