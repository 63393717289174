import Decimal from 'decimal.js';
import { DriverType } from '../../../../interfaces/delivery-company/drivers';

export const emptyDriver: DriverType = {
  id: '',
  name: '',
  phone_number: '',
  default_commission: [],
  is_deleted: false,
  has_usable_password: false,
};
