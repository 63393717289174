import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { User } from '../../../interfaces/common/authentication';
import DeleteOrdersAction from './delete-orders-action';
import ExtractAsPDFAction from './extract-as-pdf';
import { BulkActionProps } from './props.interface';
import SetBranchAction from './set-branch-action';
import SetDeliveryStateAction from './set-delivery-state-action';
import SetDriverAction from './set-driver-action';
import { UserType } from '../../../enums/users';
import PrintWaybills from './print-waybills';

interface Actions {
  [key: string]: (props: any) => JSX.Element; //string index signature
}

const getCompanyActions = (user: User): Actions => {
  const CompanyActions: Actions = {};
  if (
    user.type === UserType.ORGANISATION ||
    user.permissions?.includes('change_order')
  ) {
    CompanyActions['Set Delivery State'] = (props: any) => (
      <SetDeliveryStateAction {...props} />
    );
    CompanyActions['Set Branch'] = (props: any) => (
      <SetBranchAction {...props} />
    );
    CompanyActions['Assign Driver'] = (props: any) => (
      <SetDriverAction {...props} />
    );
  }

  if (
    user.type === UserType.ORGANISATION ||
    user.permissions?.includes('delete_order')
  ) {
    CompanyActions['Delete Orders'] = (props: any) => (
      <DeleteOrdersAction {...props} />
    );
  }

  CompanyActions['Extract As PDF'] = (props: any) => (
    <ExtractAsPDFAction {...props} />
  );
  CompanyActions['Print Waybills'] = (props: any) => (
    <PrintWaybills {...props} />
  );

  return CompanyActions;
};

const MerchantActions = {
  'Delete Orders': (props: any) => <DeleteOrdersAction {...props} />,
  'Extract As PDF': (props: any) => <ExtractAsPDFAction {...props} />,
  'Print Waybills': (props: any) => <PrintWaybills {...props} />,
};

const getDefaultAction = (user: User | null): string => {
  if (!user) return '';
  if (user.type === UserType.MERCHANT) return 'Delete Orders';
  return 'Set Delivery State';
};

const getActions = (user: User | null): Actions => {
  if (!user) return {};
  if (user.type === UserType.MERCHANT) {
    return MerchantActions;
  }

  return getCompanyActions(user);
};
function BulkAction({
  selectedOrders,
  bulkEndpoint,
  refetchOrders,
  extractAsPdf,
}: BulkActionProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFinishAction = () => {
    setAction('')
    resetState();
  }

  const { user } = useContext(AuthContext);

  const [action, setAction] = React.useState<string>('');

  const actions = getActions(user);
  const orgId = user?.organisation?.org_id ?? 'UNKNOWN';

  const resetState = () => {
    setAction('');
  };

  useEffect(() => {
    resetState();
  }, []);


  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant='contained'
        disabled={selectedOrders.size === 0}
      >
        Actions
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {Object.keys(actions).map((action) => (
          <MenuItem onClick={() => { setAction(action); handleClose() }} key={action} value={action}>
            {action}
          </MenuItem>
        ))}

      </Menu>

      <Dialog fullWidth open={action.length > 0} onClose={onFinishAction}>
        <DialogContent>
          <br />

          <Typography
            variant="h5"
            component="h5"
            sx={{ textAlign: 'center', mt: 1, mb: 1 }}
          >
            {action}
          </Typography>

          <br />
          <br />

          {action &&
            actions[action]({
              selectedOrders,
              onFinishAction,
              bulkEndpoint,
              extractAsPdf,
              refetchOrders,
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onFinishAction}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BulkAction;
