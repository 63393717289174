import AddIcon from '@mui/icons-material/Add';
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import _debounce from 'lodash/debounce';
import { useState } from 'react';

interface AutocompleteWithThrottleProps<T> {
  label: string;
  onClickAdd?: () => void;
  onChange: (e: React.SyntheticEvent<Element, Event>, value: T | null) => void;
  fetch: (query: string) => Promise<T[]>;
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  value: T | null;
  required?: boolean;
}

function AutocompleteWithThrottle<T>({
  value,
  isOptionEqualToValue,
  label,
  onClickAdd,
  fetch,
  getOptionLabel,
  onChange,
  required,
}: AutocompleteWithThrottleProps<T>) {
  const [matchingOptions, setMatchingOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedFetchOptions = _debounce(async (query) => {
    if (query) {
      try {
        setLoading(true);
        const options = await fetch(query);
        setMatchingOptions(options);
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setMatchingOptions([]);
    }
  }, 500);

  const handleInputChange = (
    event: any,
    newInputValue: string,
    reason: string
  ) => {
    if (event && reason === 'input') {
      debouncedFetchOptions(newInputValue);
    }
  };

  return (
    <Autocomplete
      disablePortal
      options={matchingOptions}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onInputChange={handleInputChange}
      filterOptions={(x) => x}
      loading={loading}
      onChange={onChange}
      defaultValue={value ?? null}
      value={value ?? null}
      renderInput={(params) => (
        <TextField
          required={required !== undefined ? required : true}
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: onClickAdd && (
              <InputAdornment position="start">
                <IconButton onClick={onClickAdd}>
                  <AddIcon fontSize="medium" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

export default AutocompleteWithThrottle;
